import { CoreRouting } from "@build-buddy/core";
import { DraftFinancials, PublishedFinancials } from "./_components";
import FinancialsGuard from "./FinancialsGuard";
import DraftFinancialsListItemEdit from "./_components/draft-financials/DraftFinancialsListItemEdit";

const FinancialsRoutesDefinition = {
  FINANCIALS: {
    path: "/project/:pid/financials",
    exact: true,
    component: FinancialsGuard,
  },
  FINANCIALS_DRAFT: {
    path: "/project/:pid/financials/draft",
    component: DraftFinancials,
    name: "Financials Draft",
  },
  FINANCIALS_PUBLISHED: {
    path: "/project/:pid/financials/published",
    component: PublishedFinancials,
    name: "Financials Publish",
  },
  EDIT_PROJECT_ESTIMATE: {
    path: '/*/:estimateId/edit',
    component: DraftFinancialsListItemEdit
  }
};

const FinancialRoutes = () => {
  return (
    <>
      <CoreRouting.Routes routes={[FinancialsRoutesDefinition.FINANCIALS]} />
      <FinancialsGuard path={FinancialsRoutesDefinition.FINANCIALS.path}>
        <CoreRouting.Routes
          routes={[
            FinancialsRoutesDefinition.FINANCIALS_DRAFT,
            FinancialsRoutesDefinition.FINANCIALS_PUBLISHED,
            FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE,
          ]}
        />
      </FinancialsGuard>
    </>
  );
};

export { FinancialsRoutesDefinition, FinancialRoutes };
