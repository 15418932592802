import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

type FinancialsPaginationProps = {
  dataLength: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const FinancialsPagination = ({ dataLength, currentPage, setCurrentPage }: FinancialsPaginationProps) => {
  return (
    <Box 
      sx={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        gap: 2, 
        py: 2 
      }}
    >
      <Button
        disabled={currentPage < 2}
        variant="outlined"
        startIcon={<ChevronLeft />}
        onClick={() => setCurrentPage(prev => prev - 1)}
      >
        Prev
      </Button>
      <Typography >{currentPage}</Typography>
      <Button
        disabled={dataLength < 20}
        variant="outlined"
        endIcon={<ChevronRight />}
        onClick={() => setCurrentPage(prev => prev + 1)}
      >
        Next
      </Button>
    </Box>
  )
}

export default FinancialsPagination