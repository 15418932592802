import { CoreComponents, CoreState } from "@build-buddy/core";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";

const Sandbox = () => {

  const [a, b] = CoreState.User.updateUser.useMutation();
  const [open, setOpen] = useState(false);

  const handleClick = async () => {
    await a({
      userId: "4ad1c6c9-0cf0-4d42-9a46-9fb07891ba55",
      user: {
        mobile: "+61410111111"
      }
    })
  }

  return (
    <Box sx={{ width: 300, m: "0 auto" }}>
      <CoreComponents.DatePicker
        
        name=""
        onChange={() => { }}
        value={new Date()}        
      />      
    </Box>
  )
}

export default Sandbox