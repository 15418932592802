import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { transformProjectTaskFilterStatus, transformProjectTaskStatus, transformProjectTaskSubStatus } from "../_transformers";
import { ProjectTask } from "../_models";

interface SelectProjectTaskParams extends CoreState.Task.SelectTaskListByParentCurrentTaskParams {}
export const selectProjectTask = createSelector(
  CoreState.Task.selectTaskListByParentCurrentTask,
  (task): ProjectTask | undefined => {
    if (!task) return;
    const status = transformProjectTaskStatus.toModel(task);
    const subStatuses = transformProjectTaskSubStatus.toModel(task);
    const filterStatuses = transformProjectTaskFilterStatus.toModel(task, status);
    return {
      task,
      status,
      subStatuses,
      filterStatuses,
    }
  }
)

export const selectProjectTaskId = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task.id || ""
)

const selectProjectTaskStartDate = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task.startDate || ""
)

const selectProjectTaskEndDate = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task.endDate || ""
)

export const selectProjectTaskType = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task.type
)

export const selectProjectTaskStatus = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.status
)

export const selectProjectTaskName = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task.name,
)

export const selectProjectTaskIsDiy = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task.parentIsDIY
)

const selectProjectTaskhasQuote = createSelector(
  selectProjectTask,
  (projectTask) => {
    return Boolean(projectTask?.task.hasQuote);
  }
)

export const selectProjectTaskStageDefinitionId = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task?.stage
)

export const selectProjectTaskStageDefinitionName = createSelector(
  selectProjectTask,
  (projectTask) => projectTask?.task?.stageName
)
