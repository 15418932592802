import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import { CoreUtils, Truncate } from "@build-buddy/core";
import ActualCostListTemplate from "./ActualCostListTemplate";

interface ActualCostListItemProps {
  code: string;
  title: string;
  committed: number;
  variations: number;
  due: number;
  paid: number;
  totalCost: number;
  totalBudget: number;
  actualForecast: number;
  difference: number;
  quantitySurveyorItemsSum: number;
  onEditClick(): void;
}
const ActualCostListItem = (props: ActualCostListItemProps) => {
  const theme = useTheme();
  const { difference } = props;
  let percentage = 0;

  if (props.quantitySurveyorItemsSum > 0 && props.totalCost > 0 && difference !== 0) {

    percentage = Math.round((difference / props.totalBudget) * 100);
  }

  let percentageVariation = "N/A";

  if (percentage > 0 || percentage < 0) {
    percentageVariation = `${Math.abs(percentage)}%`;
  }

  let differenceText = getText(percentage);

  return (
    <ActualCostListTemplate
      sx={{
        mx: -2,
        px: 2,
        py: 2.25,
        borderBottom: `solid 1px ${theme.palette.divider}`,
        "&:first-of-type": {
          mt: -2,
        },
        "&:last-of-type": {
          borderBottom: "none",
          mb: -3
        },
        "&:hover": {
          cursor: "pointer",
          background: theme.palette.blueGrey[100]
        }
      }}
    >
      <Truncate
        TypographyProps={{ variant: "body1" }}
        text={props.code}
        sx={{ gridArea: "code", alignSelf: "center", textAlign: "start", justifyContent: "flex-start" }}
        lines={1}
      />
      <Typography variant="body1" sx={{ gridArea: "section", alignSelf: "center", textAlign: "left" }}>
        {props.title}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "committed", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.committed, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "due", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.due, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "paid", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.paid, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "total-cost", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.totalCost, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "actual-forecast", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.actualForecast, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "total-budget", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.quantitySurveyorItemsSum, "-")}
      </Typography>
      <Box sx={{ gridArea: "chip", ml: 1, alignSelf: "center", textAlign: "right" }}>
        <Tooltip
          title={<>
            <Typography variant="body1">
              {differenceText.replace("{0}", percentageVariation)}
            </Typography>
          </>}
        >
          <Chip
            label={`$${(props.quantitySurveyorItemsSum > 0 && props.totalCost > 0) ? Math.abs(props.difference) : 0}`}
            color={percentage > 0 ? "success" : percentage < 0 ? "error" : "primary"}
          />
        </Tooltip>
      </Box>
    </ActualCostListTemplate>
  )
}
export default ActualCostListItem;

function getText(percentage: number) {
  let savingsText = "N/A";
  if (percentage > 0) {
    savingsText = "You saved {0} on the original budget set for this item";
  }
  else if (percentage < 0) {
    savingsText = "You spent {0} more than the original budget set for this item";
  }
  return savingsText;
}