import { CoreState } from "@build-buddy/core";
import { Box, Grid } from "@mui/material";
import { useProjectParams } from "modules/project/_hooks";
import DashboardFinancialsDetailCompletion from "./dashboard-financials-detail/DashboardFinancialsDetailCompletion";
import DashboardFinancialsDetailSaving from "./dashboard-financials-detail/DashboardFinancialsDetailSaving";
import DashboardPie from "./dashboard-financials-pie/DashboardFinancialsPie";
import DashboardFinancialsSkeleton from "./DashboardFinancialsSkeleton";

const DashboardFinancials = () => {
  const { projectId } = useProjectParams();
  
  const projectFinancialsQuery = CoreState.ProjectFinancials.getProjectFinancials.useQuery(
    { projectId },
  );

  const isLoading = projectFinancialsQuery.isLoading;

  if (isLoading) return <DashboardFinancialsSkeleton />

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={7}>
          <DashboardPie />
        </Grid>
        <Grid item xs={12} lg={4} xl={5}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              gap: 2,
              flexDirection: {
                xs: "row",
                lg: "column"
              }
            }}
          >
            <DashboardFinancialsDetailCompletion />
            <DashboardFinancialsDetailSaving />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default DashboardFinancials;