import { CoreState } from "@build-buddy/core";
import { Lock as LockIcon } from "@mui/icons-material";
import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router";
import ActualCost from "../actual-cost/ActualCost";
import BudgetCost from "../budget-cost/BudgetCost";
import QuantitySurveyorCost from "../quantity-surveyor-cost/QuantitySurveyorCost";

const PublishedFinancials = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const tabs = ["actual-cost", "budget-cost", "quantity-surveyor-cost"];

  // we need to find the initial index, so we can maintain the initial path (before any redirects)
  const initialIndex = tabs.findIndex((x) => location.pathname.includes(x));

  // set it to the tab, otherwise default it to 0
  const [currentIndex, setCurrentIndex] = useState(initialIndex === -1 ? 0 : initialIndex);

  const projectId = useSelector(CommonState.App.selectCurrentProjectId);

  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  useEffect(() => {
    if (Boolean(isLimited)) {
      history.push(`/project/${projectId}/dashboard`);
    }
  }, [history, isLimited, projectId]);

  useEffect(() => {
    const tabIndex = tabs.findIndex((x) => location.pathname.includes(x));
    if (tabIndex === currentIndex) return;
    history.push(`${match.url}/${tabs[currentIndex]}`);
  }, [location, currentIndex]);

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {" "}
            Project Financials and Budget{" "}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center">
          <LockIcon color="primary" />
          <Typography variant="body1" sx={{ mt: 0.5, ml: 0.5 }}>
            {" "}
            Budget Locked{" "}
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ borderBottom: `solid 1px ${theme.palette.grey[300]}`, mb: 3, mx: -3, px: 3 }}>
        <Tabs value={currentIndex} onChange={(e, v) => setCurrentIndex(v)}>
          <Tab label="Actual Cost" />
          <Tab label="Budget Cost" />
          <Tab label="Quantity Surveyor Cost" />
        </Tabs>
      </Box>
      <Box sx={{ overflowX: "scroll", minHeight: "80vh" }}>
        <Switch>
          <Route path={`${match.url}/actual-cost`} component={() => <ActualCost />} />
          <Route path={`${match.url}/budget-cost`} component={() => <BudgetCost />} />
          <Route path={`${match.url}/quantity-surveyor-cost`} component={() => <QuantitySurveyorCost />} />
        </Switch>
      </Box>
    </>
  );
};
export default PublishedFinancials;
