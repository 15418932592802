import { Actions, CoreComponents, CoreState, Loader, Money, useMode } from "@build-buddy/core";
import { DialogActions, DialogContent, Stack, TextField } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { Formik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router";
import DraftFinancialsListItemEditSkeleton from "./DraftFinancialsListItemEditSkeleton";

type DraftFinancialsListItemEditContentProps = {
  onClose(): void;
}
const DraftFinancialsListItemEditContent = (props: DraftFinancialsListItemEditContentProps) => {
  const params = useParams<any>();
  const mode = useMode();
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const [saveProjectEstimateItemMutation, saveProjectEstimateItemQuery] = CoreState.ProjectFinancials.saveProjectEstimateItem.useMutation();
  const getProjectEstimateItemQuery = CoreState.ProjectFinancials.getProjectEstimateItem.useQuery({
    projectId: projectId,
    id: params.estimateId,
    quantitySurveyorGroupId: "1"
  })

  const handleSave = (values: any) => {
    saveProjectEstimateItemMutation({
      projectId: projectId,
      quantitySurveyorGroupId: "1",
      id: params.estimateId,
      quantity: values.qty,
      rate: values.rate,
      notes: values.notes
    });
  }

  useEffect(() => {
    if (saveProjectEstimateItemQuery.isSuccess) {
      props.onClose();
    }
  }, [saveProjectEstimateItemQuery.isSuccess])

  const data = getProjectEstimateItemQuery.data;

  return (
    <CoreComponents.PreContent
      isLoading={getProjectEstimateItemQuery.isLoading}
      loader={<DraftFinancialsListItemEditSkeleton />}
      isEmpty={false}
    >
      <Formik
        initialValues={{
          description: data?.description,
          notes: data?.notes || "",
          // amount: data.budget || 0,
          rate: data?.rate || 0,
          qty: data?.quantity || 0
        }}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {formik => (
          <>
            <DialogContent>
              <Loader show={saveProjectEstimateItemQuery.isLoading} />
              <TextField
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                fullWidth
                disabled
              />
              <Stack
                direction={mode.sm ? "row" : "column"}
                spacing={mode.sm ? 2 : 0}
                sx={{ mb: mode.sm ? 2 : 0 }}
              >
                <TextField
                  name="qty"
                  label="Quantity"
                  value={formik.values.qty}
                  onChange={formik.handleChange}
                  fullWidth
                />
                <TextField
                  name="rate"
                  label="Rate"
                  value={formik.values.rate}
                  onChange={formik.handleChange}
                  InputProps={{ inputComponent: Money as any }}
                  fullWidth
                />
              </Stack>
              <TextField
                name="notes"
                label="Notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                multiline
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Actions
                submitText="Save"
                onSubmitClick={() => formik.handleSubmit()}
                onCancelClick={() => props.onClose()}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </CoreComponents.PreContent>
  )
}
export default DraftFinancialsListItemEditContent;