import { CoreComponents, CoreRouting, CoreState, useDebounce } from "@build-buddy/core";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { FinancialsRoutesDefinition } from "../../FinancialsRoutes";
import FinancialsPagination from "../FinancialsPagination";
import TableHeaderItem from "../TableHeaderItem";
import TableSkeleton from "../TableSkeleton";
import BudgetCostListItem from "./BudgetCostListItem";
import BudgetCostListTemplate from "./BudgetCostListTemplate";

const BudgetCost = () => {
  const routing = CoreRouting.useRouting();

  // selectors
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);

  // Current page for pagination
  const [currentPage, setCurrentPage] = useState(1);


  // Search filter states
  const [itemSearchKeyword, setItemSearchKeyword] = useState('');
  const debouncedItemSearchTerm = useDebounce(itemSearchKeyword, 500);

  useEffect(() => {
    if (itemSearchKeyword === '') return;
    if (currentPage === 1) return;
    setCurrentPage(1);
  }, [itemSearchKeyword])

  // Table header items
  const tableHeaders = [
    { label: 'Cost Centre Code', gridArea: 'sectionCode', fieldName: 'sectionCode', align: 'left' },
    { label: 'Item Code', gridArea: 'code', fieldName: 'code', align: 'left' },
    { label: 'Item', gridArea: 'item', fieldName: 'description', align: 'left' },
    { label: 'Original Budget', gridArea: 'original-budget', fieldName: 'budget' },
    { label: 'Variations', gridArea: 'variations', fieldName: 'variations', ml: 2 },
    { label: 'Rate', gridArea: 'rate', fieldName: 'rate', ml: 2 },
    { label: 'Quantity', gridArea: 'qty', fieldName: 'quantity' },
    { label: 'Total Budget', gridArea: 'total-budget', fieldName: 'totalBudget' },
    { label: '', gridArea: 'chip', fieldName: '' },
    { label: 'Notes', gridArea: 'notes', fieldName: '', ml: 2, align: 'left' }
  ];
  // queries
  const getProjectEstimateItemListQuery =
    CoreState.ProjectFinancials.getProjectEstimateItemList.useQuery({
      projectId: projectId,
      BOQVersionType: '',
      currentPage,
      pageSize: 20,
      sectionSearchKeyword: '',
      nameSearchKeyword: debouncedItemSearchTerm,
    });

  const data = getProjectEstimateItemListQuery.data;

  return (
    <>
      <Card sx={{ minWidth: '1250px' }}>
        <CardHeader
          title={
            <BudgetCostListTemplate>
              {tableHeaders?.map((header, i) => {
                return <Box key={i}>
                  <TableHeaderItem
                    title={header.label}
                    gridArea={header.gridArea}
                    align={header.align}
                    ml={header.ml}
                    onItemSearchChange={setItemSearchKeyword}
                    onSectionSearchChange={() => null}
                  />

                </Box>;
              })}
            </BudgetCostListTemplate>
          }
        />
        <CoreComponents.PreContent
          isLoading={getProjectEstimateItemListQuery.isLoading || getProjectEstimateItemListQuery.isFetching}
          isEmpty={!data?.length}
          loader={<TableSkeleton />}
        >
          <CardContent>
            {data?.map((d: any, idx: number) => (
              <BudgetCostListItem
                key={idx}
                sectionCode={d.sectionCode}
                code={d.code}
                title={d.description}
                originalBudget={d.budget}
                variations={d.variations}
                quantity={d.quantity}
                rate={d.rate}
                totalBudget={d.totalBudget}
                notes={d.notes}
                onEditClick={() => routing.go(FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE, { params: { estimateId: d.id } })}
              />
            ))}
          </CardContent>
        </CoreComponents.PreContent>
        <FinancialsPagination
          dataLength={(data && data.length) || 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Card>
      <Switch>
        <Route
          path={FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE.path}
          component={FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE.component}
        />
      </Switch>
    </>
  );
};
export default BudgetCost;
