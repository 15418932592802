import { CoreComponents, CoreHooks, DialogHeader } from '@build-buddy/core';
import { Box, Dialog, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import GanttChartDialogContent from './GanttChartDialogContent';
import { GanttTask } from './useGanttChartData';

interface GanttChartDialogProps {
  currentTask?: GanttTask;
  show: boolean;
  onClose(): void;
}

const GanttChartDialog = (props: GanttChartDialogProps) => {
  const mode = CoreHooks.useMode()
  const { currentTask, show, onClose } = props;

  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    if (show) {
      setOpenDrawer(true)
    } 
  }, [show])

  const handleClose = () => {
    setOpenDrawer(prev => !prev)
    onClose()
  }

  if (mode.isMobile) {
    return (
      <CoreComponents.MobileDrawer open={openDrawer} setOpen={() => handleClose()}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 2, py: 4 }}>
          <GanttChartDialogContent currentTask={currentTask} />
        </Box>
      </CoreComponents.MobileDrawer>
    )
  }

  return (
    <Dialog open={openDrawer}>
      <DialogHeader title={currentTask?.title} onClose={() => handleClose()} />
      <DialogContent>
        <GanttChartDialogContent currentTask={currentTask} />
      </DialogContent>
    </Dialog>
  )
}

export default GanttChartDialog