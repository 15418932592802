import { CoreState } from "@build-buddy/core";
import { Box, Button, Collapse, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectProjectCompletedPercent } from "modules/project/_selectors/selectProject";
import { useState } from "react";
import DashboardProjectBreakdownItem from "./DashboardProjectBreakdownItem";
import { selectDashboardProjectStageList } from "./_selectors";

const DashboardProjectBreakdown = () => {
  const params = useProjectParams();
  const [open, setOpen] = useState(false);

  // queries
  const getProjectStageListQuery = CoreState.Project.getProjectStageList.useQuery(
    { projectId: params.projectId },
    { skip: !params.projectId }
  );

  // selectors
  const projectCompletedPercent = useSelector((s) => selectProjectCompletedPercent(s, { projectId: params.projectId }));
  const projectStageList = useSelector((s) => selectDashboardProjectStageList(s, { projectId: params.projectId }));

  // vars
  const isLoading = getProjectStageListQuery.isLoading;
  const progressBtnText = open ? "Hide progress breakdown" : "Progress breakdown"

  // event handlers
  const handleExpand = () => setOpen(!open);

  if (isLoading) return null;

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ mb: 0.5 }}> 
          {projectCompletedPercent}% of all tasks completed 
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={projectCompletedPercent} 
          sx={{ height: "10px", borderRadius: 4 }} 
        />
      </Box>

      <Collapse in={open}>
        {projectStageList.map((ps, idx) => (
          <DashboardProjectBreakdownItem
            key={idx}
            name={ps.name}
            total={ps.progress.totalTasks}
            completed={ps.progress.completedTasks}
            percent={ps.progress.completedPercent}
          />
        ))}
      </Collapse>

      <Button
        variant="outlined"
        color="neutral"
        fullWidth
        onClick={() => handleExpand()}
        sx={{
          display:{
            xs: "none",
            md: "block",
          }
        }}
      >
        {progressBtnText}
      </Button>
    </>
  )
}
export default DashboardProjectBreakdown;
