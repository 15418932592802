import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import { CoreUtils } from "@build-buddy/core";

interface DifferenceChipNegativeProps {
  percent: number;
  offset: number;
  value: number;
}
const DifferenceChipNegative = (props: DifferenceChipNegativeProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={<>
        <Typography variant="body1">
          Your total budget has exceeded
          <Box component="span" sx={{ color: theme.palette.warning.light, fontWeight: 600 }}> {props.percent.toFixed(0)}% more than </Box>
          the original budget previously set. You are over by
          <Box component="span" sx={{ color: theme.palette.warning.light, fontWeight: 600 }}> {CoreUtils.Formatter.currency(props.value)} </Box>
        </Typography>
      </>}
    >
      <Chip
        label={`${props.offset.toFixed(0)}%`}
        color="error"
        sx={{ mr: 2 }}
      />
    </Tooltip>
  )
}
export default DifferenceChipNegative;