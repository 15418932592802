import { Confirmation, CoreComponents, CoreHooks, CoreRouting, CoreState, Loader, useDebounce } from "@build-buddy/core";
import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { useProjectParams } from "modules/project/_hooks";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import FinancialsPagination from "../FinancialsPagination";
import TableHeaderItem from "../TableHeaderItem";
import TableSkeleton from "../TableSkeleton";
import DraftFinancialsListItem from "./DraftFinancialsListItem";
import DraftFinancialsListItemEdit from "./DraftFinancialsListItemEdit";
import DraftFinancialsListTemplate from "./DraftFinancialsListTemplate";
import UploadFinancials from "./UploadFinancials";
import VersionSelectNav from "./VersionSelectNav";
import { useRouting } from "@build-buddy/core/dist/routing";
import { FinancialsRoutesDefinition } from "../../FinancialsRoutes";

// Functiona to calculate total amount acc to Unit
export const calculateTotal = (rate: number, quantity: number, divisor: number) => {
  const d = !divisor ? 1 : divisor
  return (rate * quantity) / d;
};

const DraftFinancials = () => {
  // hooks
  const match = useRouteMatch();
  const history = useHistory();
  const routing = CoreRouting.useRouting();
  const { projectId } = useProjectParams();

  // queries
  const getBoqVersions = CoreState.ProjectFinancials.getBoqVersions.useQuery(null);
  const versions = getBoqVersions.data?.versions as CoreState.ProjectFinancials.BoqVersionType[];

  // internal state
  const [confirm, setConfirm] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const tableHeaders = [
    { label: 'Section', gridArea: 'section', fieldName: 'section', align: 'left' },
    { label: 'Item', gridArea: 'item', fieldName: 'description', align: 'left' },
    { label: 'Quantity', gridArea: 'qty', fieldName: 'quantity' },
    { label: 'Unit', gridArea: 'unit', fieldName: 'unitType', ml: 2 },
    { label: 'Rate', gridArea: 'rate', fieldName: 'rate', ml: 2 },
    { label: 'Total Amount', gridArea: 'total', fieldName: 'variation' },
    { label: 'Notes', gridArea: 'notes', fieldName: '', ml: 2, align: 'left' }
  ];

  // The data is displayed based on this state
  const [currentVersion, setCurrentVersion] = useState("PricingEstimator");
  // Current page for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Search filter states
  const [sectionSearchKeyword, setSectionSearchKeyword] = useState('');
  const [itemSearchKeyword, setItemSearchKeyword] = useState('');
  const debouncedSectionSearchTerm = useDebounce(sectionSearchKeyword, 500);
  const debouncedItemSearchTerm = useDebounce(itemSearchKeyword, 500);

  useEffect(() => {
    if (sectionSearchKeyword === '' && itemSearchKeyword === '') return;
    if (currentPage === 1) return;
    setCurrentPage(1);
  }, [sectionSearchKeyword, itemSearchKeyword])

  // Reset currentpage when currentVersion is changed.
  useEffect(() => {
    setCurrentPage(1)
    setSectionSearchKeyword('');
    setItemSearchKeyword('');
  }, [currentVersion])

  // queries
  const getProjectEstimateItemListQuery = CoreState.ProjectFinancials.getProjectEstimateItemList.useQuery({
    projectId: projectId,
    BOQVersionType: currentVersion,
    currentPage,
    pageSize: 20,
    sectionSearchKeyword: debouncedSectionSearchTerm,
    nameSearchKeyword: debouncedItemSearchTerm,
  });

  // mutations
  const [lockBudgetMutation, lockBudgetQuery] = CoreState.Project.lockBudget.useMutation();

  // handlers
  const handleConfirm = async () => {
    await lockBudgetMutation({ projectId: projectId });
  };

  // Send to locked budget screen after successfully locking the budget.
  useEffect(() => {
    if (lockBudgetQuery?.data?.success && lockBudgetQuery?.isSuccess) {
      setConfirm(false);
      history.push(`/project/${projectId}/financials/published`)
    }
  }, [confirm, lockBudgetQuery])

  // Version select handlers for tabs.
  const handleVersionSelect = (version: string) => {
    setCurrentVersion(version);
  };

  const data = getProjectEstimateItemListQuery.data

  if (!versions) return null;

  return (
    <>
      {/* Upload Dialog */}
      <UploadFinancials
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        selectedVersion={currentVersion}
      />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 2, sm: 0 }}
        alignItems="center"
        sx={{ mb: 2, px: { xs: 1, sm: 0 } }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Project Financials and Budget
          </Typography>
          <Typography variant="body1">Your Standard, Initial, Final Bill of Quantities (BoQ) and Quantity Surveyor Report (QS Report)</Typography>
        </Box>
        <Button
          hidden
          variant="contained"
          color="primary"
          onClick={() => setConfirm(true)}
        >
          Lock budget
        </Button>
      </Stack>

      <VersionSelectNav
        versions={versions}
        currentVersion={currentVersion}
        onSelect={handleVersionSelect}
      />

      <Box sx={{ overflowX: "scroll" }}>
        <Card sx={{ minWidth: "1250px" }}>
          <Loader show={getProjectEstimateItemListQuery.isFetching} />
          <CardHeader
            title={
              <DraftFinancialsListTemplate sx={{ alignItems: 'center' }}>
                {tableHeaders?.map((header, i) => {
                  return <Box key={i}>
                    <TableHeaderItem
                      title={header.label}
                      gridArea={header.gridArea}
                      align={header.align}
                      ml={header.ml}
                      sectionSearchKeyword={sectionSearchKeyword}
                      itemSearchKeyword={itemSearchKeyword}
                      onItemSearchChange={setItemSearchKeyword}
                      onSectionSearchChange={setSectionSearchKeyword}
                    />
                  </Box>;
                })}
              </DraftFinancialsListTemplate>
            }
          />
          <CoreComponents.PreContent
            isLoading={getProjectEstimateItemListQuery.isLoading}
            isEmpty={!data?.length}
            loader={<TableSkeleton />}
          >
            <CardContent>
              {data?.map((d: any, idx: number) => (
                <DraftFinancialsListItem
                  key={idx}
                  title={d.description}
                  section={d.section}
                  amount={d.budget || 0}
                  quantity={d.quantity || 0}
                  unit={d.unitType.type}
                  rate={d.rate}
                  total={calculateTotal(d.rate, d.quantity, d.unitType.divisor)}
                  notes={d.notes}
                  onEditClick={() => routing.go(FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE, { params: { estimateId: d.id } })}
                />
              ))}
            </CardContent>
          </CoreComponents.PreContent>
          <FinancialsPagination
            dataLength={(data && data.length) || 0}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card>
      </Box>
      <Confirmation
        open={confirm}
        title="Lock budget"
        error={lockBudgetQuery?.data?.error}
        text="Are you sure you want to lock budget?"
        loading={lockBudgetQuery.isLoading}
        onCancel={() => setConfirm(false)}
        onClose={() => setConfirm(false)}
        onConfirm={() => handleConfirm()}
      />
      <Switch>
        <Route
          path={FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE.path}
          component={FinancialsRoutesDefinition.EDIT_PROJECT_ESTIMATE.component}
        />
      </Switch>
    </>
  );
};
export default DraftFinancials;
