import { Card, CardContent, Box, SxProps, Theme } from "@mui/material";

type DashboardFinancialsDetailProps = {
  children: any;
  sx?: SxProps<Theme>;
}
const DashboardFinancialsDetail = (props: DashboardFinancialsDetailProps) => {
  return (
    <Card 
      sx={{ 
        flex: 1, 
        ...props.sx 
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "100%"
        }}
      >        
        <Box>
          {props.children}
        </Box>
      </CardContent>
    </Card>
  )
}
export default DashboardFinancialsDetail;