import { CoreUtils } from "@build-buddy/core";
import { Box, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import useProjectParams from "modules/project/_hooks/useProjectParams";
import { selectProjectBillOfQuantityVersion } from "modules/project/_selectors";
import { selectDashboardFinancialsIsBoquploaded, selectDashboardFinancialsSavings } from "../_selectors/selectDashboardFinancials";
import DashboardFinancialsDetail from "./DashboardFinancialsDetail";

const kvp = {
  "BuildBuddyDefault": "Standard Estimate",
  "PricingEstimator": "Initial Estimate",
  "ProductionEstimator": "Final Estimate",
  "QuantitySurveyor": "Quantity Surveyor"
} as const;

const DashboardFinancialsDetailSaving = () => {
  const theme = useTheme();
  const { projectId } = useProjectParams();
  const savings = useSelector((s) => selectDashboardFinancialsSavings(s, { projectId }));
  const isBoqUploaded = useSelector((s) => selectDashboardFinancialsIsBoquploaded(s, { projectId }));
  const boqVersion = useSelector((s) => selectProjectBillOfQuantityVersion(s, { projectId }));

  return (
    <DashboardFinancialsDetail
      sx={{
        background: "linear-gradient(93deg, #FF6F00 0%, #FFB800 100%)",
        color: theme.palette.common.white
      }}
    >
      <Box>
        {isBoqUploaded && <Typography
          variant="body1"
        >
          You're on track to save
        </Typography>}
        <Typography
          variant="h1"
          sx={{
            mt: 1,
            fontSize: {
              xs: "18px",
              sm: "24px"
            }
          }}
        >
          {isBoqUploaded ? CoreUtils.Formatter.currency(savings, "Not Applicable") : "Awaiting Initial Pricing Estimate"}

        </Typography>
        {isBoqUploaded && <Typography
          variant="body2"
          sx={{ mt: 1 }}
        >
          Based on {kvp[boqVersion]}
        </Typography>}
      </Box>
    </DashboardFinancialsDetail>
  )
}
export default DashboardFinancialsDetailSaving;