import { IconButton, Typography, useTheme } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { CoreUtils, Truncate } from "@build-buddy/core";
import BudgetCostListTemplate from "./BudgetCostListTemplate";
import DifferenceChip from "../difference-chip/DifferenceChip";

interface BudgetCostListItemProps {
  sectionCode: string;
  code: string;
  title: string;
  originalBudget: number;
  variations: number;
  quantity: number;
  totalBudget: number;
  notes: string | undefined;
  onEditClick(): void;
  rate: number;
}
const BudgetCostListItem = (props: BudgetCostListItemProps) => {
  const theme = useTheme();
  return (
    <BudgetCostListTemplate
      sx={{
        mx: -2,
        px: 2,
        py: 2.25,
        borderBottom: `solid 1px ${theme.palette.divider}`,
        "&:first-of-type": {
          mt: -2,
        },
        "&:last-of-type": {
          borderBottom: "none",
          mb: -3
        },
        "&:hover": {
          cursor: "pointer",
          background: theme.palette.blueGrey[100]
        }
      }}
    >
      <Truncate
        TypographyProps={{ variant: "body1" }}
        text={props.sectionCode}
        sx={{ gridArea: "sectionCode", alignSelf: "center" }}
        lines={1}
      />
      <Typography variant="body1" sx={{ gridArea: "code", alignSelf: "center", textAlign: "left" }}>
        {props.code}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "item", alignSelf: "center", textAlign: "left" }}>
        {props.title}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "original-budget", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.originalBudget, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "variations", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.variations, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "rate", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.rate, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "qty", alignSelf: "center", textAlign: "center" }}>
        {props.quantity}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "total-budget", alignSelf: "center", textAlign: "right", mr: 1 }}>
        {CoreUtils.Formatter.currency(props.rate * props.quantity?? props.originalBudget, "-")}
      </Typography>
      <DifferenceChip budgeted={props.originalBudget} actual={props.totalBudget} />
      <Truncate
        TypographyProps={{ variant: "body1" }}
        text={props.notes || "-"}
        sx={{ gridArea: "notes", alignSelf: "center", ml: 2 }}
        lines={1}
      />
      <IconButton edge="end" onClick={props.onEditClick} sx={{gridArea: 'action'}}> 
        <EditIcon fontSize="small" color="primary" /> 
      </IconButton>
    </BudgetCostListTemplate>
  )
}
export default BudgetCostListItem;