import { CoreUtils } from "@build-buddy/core";
import { Box, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectProject } from "modules/project/_selectors";
import DashboardFinancialsDetail from "./DashboardFinancialsDetail";

const DashboardFinancialsDetailCompletion = () => {
  const params = useProjectParams();

  const project = useSelector((s) => selectProject(s, { projectId: params.projectId }));

  return (
    <DashboardFinancialsDetail>
      <Box>
        <Typography
          variant="body1"
        >
          Projected Completion Date
        </Typography>
        <Typography
          variant="h1"
          sx={{
            mt: 1,
            fontSize: {
              xs: "18px",
              sm: "24px"
            }
          }}
        >
          {CoreUtils.Formatter.date(project?.endDate) || "Not Applicable"}
        </Typography>
      </Box>
    </DashboardFinancialsDetail>
  )
}
export default DashboardFinancialsDetailCompletion;