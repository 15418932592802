import { Box, SxProps, Theme, useTheme } from "@mui/material"

interface ActualCostListTemplateProps {
  children: any;
  sx?: SxProps<Theme>;
}
const ActualCostListTemplate = (props: ActualCostListTemplateProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: `
          'code section committed due paid total-cost actual-forecast total-budget chip'
        `,
        gridColumnGap: theme.spacing(1.5),
        gridTemplateColumns: "130px 200px 100px 100px 100px 100px 125px 125px 125px",
        ...props.sx
      }}
    >
      {props.children}
    </Box>
  )
}
export default ActualCostListTemplate;