import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import { CoreUtils } from "@build-buddy/core";

interface DifferenceChipPositiveProps {
  percent: number;
  offset: number;
  value: number;
}
const DifferenceChipPositive = (props: DifferenceChipPositiveProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={<>
        <Typography variant="body1">
          Your total budget is under by
          <Box component="span" sx={{ color: theme.palette.success.light, fontWeight: 600 }}> {props.percent.toFixed(0)}%&nbsp; </Box>
          the original budget previously set. You are under by
          <Box component="span" sx={{ color: theme.palette.success.light, fontWeight: 600 }}> {CoreUtils.Formatter.currency(props.value)} </Box>
        </Typography>
      </>}
    >
      <Chip
        label={`${props.offset.toFixed(0)}%`}
        color="success"
        sx={{ mr: 2 }}
      />
    </Tooltip>
  )
}
export default DifferenceChipPositive;