import { CoreComponents, CoreState } from '@build-buddy/core'
import { PredecessorStatus } from '@build-buddy/core/dist/state/task'
import ProjectTaskStatusPredecessorsStatus from './ProjectTaskStatusPredecessorsStatus'
import ProjectTaskStatusPredecessorsSkeleton from './ProjectTaskStatusPredecessorsSkeleton';
import { Box, Typography, useTheme } from '@mui/material';

type ProjectTaskStatusPredecessorsListProps = {
  predecessorParams: {
    projectId: string;
    taskId: string;
    stageId: string;
    subTaskId: string;
  };
}
const ProjectTaskStatusPredecessorsList = (props: ProjectTaskStatusPredecessorsListProps) => {
  const { predecessorParams } = props;
  const theme = useTheme()

  // Query
  const getPredecessor = CoreState.Task.getPredecessorTaskList.useQuery({
    projectId: predecessorParams.projectId,
    taskId: predecessorParams.taskId,
    stageId: predecessorParams.stageId,
    subTaskId: predecessorParams.subTaskId
  });

  const predecessors = getPredecessor.data;

  // If task is in Outstanding(Pending) status, get the end date to show it in the last pre-requisite task.
  let lastItemDate;
  if (predecessors?.length > 0) {
    lastItemDate = predecessors[predecessors.length - 1].status === PredecessorStatus.Pending ?
      predecessors[predecessors.length - 1].endDate :
      null
  }

  return (
    <CoreComponents.PreContent
      isLoading={getPredecessor.isLoading}
      loader={<ProjectTaskStatusPredecessorsSkeleton />}
      isEmpty={!getPredecessor.data?.length}
      empty={<Box sx={{ px: 2, py: 1 }}>No Pre-requisite tasks.</Box>}
    >
      <>
        <Typography
          variant='h4'
          px={2}
          py={1}
          sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}
        >
          Pre-Requisite Tasks
        </Typography>
        <CoreComponents.Timeline
          lastTimelineItem={{
            label: "",
            date: lastItemDate,
            sx: {
              px: 2,
            }
          }}
        >
          {predecessors?.map((predecessor: CoreState.Task.Predecessor) => {
            return (
              <CoreComponents.TimelineItem
                key={predecessor.id}
                title={predecessor.name}
                status={
                  <ProjectTaskStatusPredecessorsStatus
                    predecessor={predecessor}
                  />
                }
                date={predecessor.status === PredecessorStatus.Complete ? predecessor.endDate : predecessor.startDate}
                sx={{ px: 2 }}
                isInProgress={Boolean(predecessor.status === PredecessorStatus.Pending)}
                isCompleted={Boolean(predecessor.status === PredecessorStatus.Complete)}
              />
            )
          })}
        </CoreComponents.Timeline>
      </>
    </CoreComponents.PreContent>
  )
}
export default ProjectTaskStatusPredecessorsList