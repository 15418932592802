import { CoreComponents, CoreState, useDebounce } from "@build-buddy/core";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import FinancialsPagination from "modules/project/financials/_components/FinancialsPagination";
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import TableHeaderItem from "../TableHeaderItem";
import TableSkeleton from "../TableSkeleton";
import ActualCostListItem from "./ActualCostListItem";
import ActualCostListTemplate from "./ActualCostListTemplate";

const ActualCost = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);

  // Search filter states
  const [sectionSearchKeyword, setSectionSearchKeyword] = useState('');
  const debouncedItemSearchTerm = useDebounce(sectionSearchKeyword, 500);

  useEffect(() => {
    if (sectionSearchKeyword === '') return;
    if (currentPage === 1) return;
    setCurrentPage(1);
  }, [sectionSearchKeyword])

  // selectors
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);

  // Table header items
  const tableHeaders = [
    { label: 'Cost Centre Code', gridArea: 'code', fieldName: 'quantitySurveyorGroupCode', align: 'left' },
    { label: 'Cost Centre Name', gridArea: 'section', fieldName: 'quantitySurveyorGroupName', align: 'left' },
    { label: 'Commited', gridArea: 'committed', fieldName: 'committed' },
    { label: 'Due', gridArea: 'due', fieldName: 'due' },
    { label: 'Paid', gridArea: 'paid', fieldName: 'paid', ml: 2 },
    { label: 'Total Cost', gridArea: 'total-cost', fieldName: 'totalCost' },
    { label: 'Total Forecast', gridArea: 'actual-forecast', fieldName: 'actualForecast' },
    { label: 'Original Budget', gridArea: 'total-budget', fieldName: 'totalBudget' },
    { label: 'Difference', gridArea: 'chip', fieldName: 'chip' },
    //  {label: 'Notes', gridArea: 'notes', fieldName: '', ml: 2}
  ];

  // queries
  const getProjectQuantitySurveyorGroupFinancialsListQuery = CoreState.ProjectFinancials.getProjectQuantitySurveyorGroupFinancialsList.useQuery({
    projectId,
    currentPage,
    pageSize: 20,
    sectionSearchKeyword: debouncedItemSearchTerm,
    nameSearchKeyword: '',
  });

  const data: any = getProjectQuantitySurveyorGroupFinancialsListQuery.data;

  return (
    <>
      <Card sx={{ minWidth: '1250px' }}>
        <CardHeader
          title={
            <ActualCostListTemplate>
              {tableHeaders?.map((header, i) => {
                return <Box key={i}>
                  <TableHeaderItem
                    title={header.label}
                    gridArea={header.gridArea}
                    ml={header.ml}
                    align={header.align}
                    onSectionSearchChange={setSectionSearchKeyword}
                    onItemSearchChange={() => null}
                  />
                </Box>;
              })}
            </ActualCostListTemplate>
          }
        />
        <CoreComponents.PreContent
          isLoading={getProjectQuantitySurveyorGroupFinancialsListQuery.isLoading}
          isEmpty={!data?.length}
          loader={<TableSkeleton />}
        >
          <CardContent>
            {data?.map((d: any, idx: number) => (
              <ActualCostListItem
                key={idx}
                code={d.quantitySurveyorGroupCode}
                title={d.quantitySurveyorGroupName}
                committed={d.financials.committed}
                due={d.financials.due}
                paid={d.financials.paid}
                variations={d.financials.budget - (d.financials.committed + d.financials.due + d.financials.paid)}
                totalBudget={d.financials.budget}
                totalCost={d.financials.committed + d.financials.due + d.financials.paid}
                actualForecast={d.financials.actualForecast}
                onEditClick={() => history.push(`${match.url}/${d.id}/edit`)}
                difference={d.financials.difference}
                quantitySurveyorItemsSum={d.financials.quantitySurveyorItemsSum}
              />
            ))}
          </CardContent>
        </CoreComponents.PreContent>
        <FinancialsPagination
          dataLength={(data && data.length) || 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Card>
    </>
  );
};
export default ActualCost;
