import { CoreComponents, CoreRouting, CoreState, DialogHeader } from '@build-buddy/core';
import { Dialog, DialogContent } from '@mui/material';
import { useEffect } from 'react';
import { useTaskViewParams } from '../../_hooks';
import { selectTaskViewQuoteListAcceptedQuoteQuoteId } from '../../_selectors';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';
import TaskViewExpenseForm from './TaskViewExpenseForm';
import { useSelector } from 'common/state/store';

const TaskViewExpenseAdd = () => {
  const taskViewParams = useTaskViewParams();
  const routing = CoreRouting.useRouting();

  const params = {
    projectId: taskViewParams.projectId,
    stageId: taskViewParams.stageId,
    taskId: taskViewParams.taskId,
  }

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(params);

  // Selector
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, params));
  const latestQuoteRequest = useSelector((s) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(s, params));

  // Mutation
  const [addExpenseAction, addExpenseQuery] = CoreState.Financials.addExpense.useMutation();

  const handleClose = () => {
    const params = {
      sid: taskViewParams.stageId,
      tid: taskViewParams.taskId
    };
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE, { params })
  }

  const handleAddExpense = async (values:any) => {
    await addExpenseAction({
      projectId: taskViewParams.projectId,
      workOrderId: quoteId || undefined,
      taskId: taskViewParams.taskId,
      quoteRequestId: latestQuoteRequest?.id || undefined,
      expense: values.expense
    })

  }

  useEffect(() => {
    if (addExpenseQuery.isSuccess) handleClose()
  }, [addExpenseQuery.isSuccess])


  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={addExpenseQuery.isLoading} />
      <DialogHeader title="Add Expense" onClose={handleClose} />
      <DialogContent>
        <TaskViewExpenseForm
          onSubmit={handleAddExpense}
          onClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  )
}

export default TaskViewExpenseAdd