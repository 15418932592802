import { Dialog } from "@mui/material";
import { CoreRouting, DialogHeader } from "@build-buddy/core";
import DraftFinancialsListItemEditContent from "./DraftFinancialsListItemEditContent";


const DraftFinancialsListItemEdit = () => {
  const routing = CoreRouting.useRouting();
  const handleBack = () => {
    routing.back()
  }
  return (
    <Dialog open={true} onClose={handleBack} sx={{maxWidth: '1000px', mx: 'auto'}}>
      <DialogHeader title="Edit details" onClose={handleBack} />
      <DraftFinancialsListItemEditContent onClose={handleBack} />
    </Dialog>
  )
}
export default DraftFinancialsListItemEdit;