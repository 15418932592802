import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import { selectTaskViewQuoteListAcceptedQuote } from "../_selectors";
import TaskViewPaymentsActions from "./TaskViewPaymentsActions";
import TaskViewPaymentsDotProgress from "./TaskViewPaymentsDotProgress";
import TaskViewPaymentsRouteDefinitions from "./TaskViewPaymentsRouteDefinitions";
import { useTaskViewPaymentsParams } from "./_hooks";
import TaskViewExpenses from "./task-view-payments-expenses/TaskViewExpenses";

const TaskViewPayments = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewPaymentsParams();
  const quoteListParams = { projectId, stageId, taskId };

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // selectors
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));

  // TODO: Remove this after use signal is implemented
  useEffect(() => {
    quoteListQuery.refetch()
  }, []);

  return (
    <>
      <CoreComponents.PreContent
        isLoading={quoteListQuery.isLoading}
        isEmpty={false}
        loader={<CoreModules.Task.TaskView.PaymentScheduleSkeleton />}
      >
        <CoreModules.Task.TaskView.TaskViewPaymentSchedule
          paymentSchedule={acceptedQuote?.paymentSchedule || []}
          actions={TaskViewPaymentsActions}
          dotProgress={TaskViewPaymentsDotProgress}
        />
        <Box sx={{ mt: 5 }}>
          <TaskViewExpenses />
        </Box>
        <Route
          component={TaskViewPaymentsRouteDefinitions.ADD_EXPENSE.component}
          path={TaskViewPaymentsRouteDefinitions.ADD_EXPENSE.path}
        />
        <Route
          component={TaskViewPaymentsRouteDefinitions.EDIT_EXPENSE.component}
          path={TaskViewPaymentsRouteDefinitions.EDIT_EXPENSE.path}
        />
        <Route
          component={TaskViewPaymentsRouteDefinitions.VIEW_EXPENSE.component}
          path={TaskViewPaymentsRouteDefinitions.VIEW_EXPENSE.path}
        />
        <Route
          component={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_VIEW_RECEIPT.component}
          path={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_VIEW_RECEIPT.path}
        />
        <Route
          component={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY.component}
          path={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY.path}
        />
        <Route
          component={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY_RECEIPT.component}
          path={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY_RECEIPT.path}
        />

      </CoreComponents.PreContent>



    </>
  )
}
export default TaskViewPayments;


