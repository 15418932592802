import { useEffect, useState } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, Tab, Tabs, useTheme } from '@mui/material';


interface VersionSelectNavProps {
  versions: { code: string, description?: string }[];
  onSelect: (version: string) => void;
  currentVersion: string;
}

const customSelectStyles = {
  fontSize: '0.78125rem',
  fontWeight: 500,
  "& #mui-component-select-projectType": {
    background: 'transparent'
  },
}

const VersionSelectNav = ({ versions, currentVersion, onSelect }: VersionSelectNavProps) => {

  // Hooks
  const theme = useTheme();

  // States
  const [currentIndex, setCurrentIndex] = useState<number | null>(0);
  const [version, setVersion] = useState<string>(currentVersion)

  const secondTab = 'QuantitySurveyor';
  const secondTabLabel = 'Quantity Surveyor'

  const tabs = [...versions].filter(x => x.code !== "QuantitySurveyor");

  // Select handler
  const handleVersionSelect = (event: SelectChangeEvent) => {
    setVersion(event.target.value);
    onSelect(event.target.value);
  }

  useEffect(() => {
    currentIndex ? onSelect(secondTab) : onSelect(version);
  }, [currentIndex])

  return (
    <Box sx={{ borderBottom: `solid 1px ${theme.palette.grey[300]}`, display: 'flex', mb: 3, mx: -3, px: 3 }}>
      <Tabs value={currentIndex} onChange={(e, v) => setCurrentIndex(v)}>
        <Tab
          sx={{
            "& .MuiInputBase-root": {
              background: 'transparent'
            }
          }}
          label={
            <Select
              name="projectType"
              value={version}
              variant="standard"
              onChange={handleVersionSelect}
              disableUnderline={true}
              sx={customSelectStyles}
            >
              {tabs.map((version, index: number) => version.code !== secondTab &&
                <MenuItem key={index} value={version.code} > {version.description} </MenuItem>)
              }
            </Select>
          }
        />
        <Tab label={secondTabLabel} />
      </Tabs>
    </Box>
  )
}

export default VersionSelectNav