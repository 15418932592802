import { CoreState } from "@build-buddy/core";
import * as Yup from "yup";

const buildWizardValidationSchema = (validations: any) => {
  if (!validations) return Yup.object().shape({});

  const schema: any = {};

  for (const key of Object.keys(validations)) {
    // get the rules for the field
    const fieldRules = validations[key];
    if (!fieldRules) continue;
    // build the yup rules out of all the field rules
    const rule = buildYupRule(fieldRules);
    if(!rule) continue;
    // assign the rule to the yup schema
    schema[key] = rule;
  }

  // TODO: HACK
  if(schema["SiteDetails__Address__Address"]) {
    schema["SiteDetails__Address__Address"] = schema["SiteDetails__Address__Address"].test("x", "Only NSW or QLD addresses are supported", (a: any, b: any) => {
      if(b.parent.SiteDetails__Address__StateCode === "NSW" || b.parent.SiteDetails__Address__StateCode === "QLD") return true;
      return false;
    })
  }

  return Yup.object().shape(schema);
}

const ruleKvp: any = {
  [CoreState.Wizard.WizardValidationType.Required]: (yupRule: any, rule: any) => {
    const message = rule.message || "This is a required field";
    return yupRule.required(message)
  },
  [CoreState.Wizard.WizardValidationType.Min]: (yupRule: any, rule: any) => {
    if(!rule.value) return yupRule;
    const message = rule.message || `Needs to be greater than ${rule.value}`;
    return yupRule.test("min", message, (value: any) => value >= rule.value);
  },
  [CoreState.Wizard.WizardValidationType.Max]: (yupRule: any, rule: any) => {
    if(!rule.value) return yupRule;
    const message = rule.message || `Needs to be less than ${rule.value}`;
    return yupRule.test("max", message, (value: any) => value <= rule.value);
  }
}

const buildYupRule = (rules: any) => {
  let yupRule = Yup.mixed();
  for(const r of rules) {
    const rule = ruleKvp[r.type]; 
    if(!rule) continue;
    yupRule = rule(yupRule, r);
  }

  return yupRule;
}


export default buildWizardValidationSchema;