import { IconButton, Typography, useTheme } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { CoreUtils, Truncate } from "@build-buddy/core";
import QuantitySurveyorCostListTemplate from "./QuantitySurveyorCostListTemplate";

interface DraftFinancialsListItemProps {
  title: string;
  amount: number;
  quantity: number;
  unit: string;
  rate: number;
  total: number;
  notes: string | undefined;
  onEditClick(): void;
}
const QuantitySurveyorCostListItem = (props: DraftFinancialsListItemProps) => {
  const theme = useTheme();
  return (
    <QuantitySurveyorCostListTemplate
      sx={{
        py: 2,
        borderBottom: `dashed 2px ${theme.palette.divider}`,
        "&:first-of-type": {
          pt: 0
        },
        "&:last-of-type": {
          borderBottom: 'none',
          pb: 0
        }
      }}
    >
      <Truncate
        TypographyProps={{ variant: "body1" }}
        text={props.title}
        sx={{ gridArea: "item", alignSelf: "center" }}
        lines={1}
      />
      <Typography variant="body1" sx={{ gridArea: "qty", alignSelf: "center", textAlign: "right" }}>
        {props.quantity}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "unit", alignSelf: "center", textAlign: "right", ml: 2 }}>
        {props.unit}
      </Typography>      
      <Typography variant="body1" sx={{ gridArea: "rate", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.rate, "-")}
      </Typography>
      <Typography variant="body1" sx={{ gridArea: "total", alignSelf: "center", textAlign: "right" }}>
        {CoreUtils.Formatter.currency(props.total, "-")}
      </Typography>
      <Truncate
        TypographyProps={{ variant: "body1" }}
        text={props.notes || "-"}
        sx={{ gridArea: "notes", alignSelf: "center", ml: 2 }}
        lines={1}
      />
      {/* <IconButton edge="end" size="small" onClick={props.onEditClick} sx={{ gridArea: 'action'}}>
        <EditIcon fontSize="small" />
      </IconButton> */}
    </QuantitySurveyorCostListTemplate>
  )
}
export default QuantitySurveyorCostListItem;