import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

export interface SelectTaskViewQuoteListParams {
  projectId: string;
  stageId: string;
  taskId: string;
}
export const selectTaskViewQuoteList = createSelector(
  [(state: CoreState.RootState, params) => CoreState.Quote.selectQuoteListByLatestQuoteRequest(state, params)],
  (quoteList) => quoteList
)

export const selectTaskViewQuoteListGrouped = createSelector(
  selectTaskViewQuoteList,
  (quoteList) => {
    if(!quoteList) return;
    const grouped = _.groupBy(quoteList, "company.id");
    return Object.keys(grouped).map(x => ({
      company: grouped[x][0].company,
      quotes: grouped[x]
    }))
  }
)

export const selectTaskViewQuoteListAcceptedQuote = createSelector(
  [
    (state, params: SelectTaskViewQuoteListParams) => CoreState.Quote.selectQuoteListByLatestQuoteRequestAcceptedQuote(state, params),
  ],
  (acceptedQuote) => acceptedQuote
)

export const selectTotalPaymentScheduleAmount = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.paymentSchedule?.reduce((acc, expense) => acc + expense.amount, 0) ?? 0,
)

export const selectTaskViewQuoteListAcceptedQuoteCompanyName = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.company?.name,
)

export const selectTaskViewQuoteListAcceptedQuoteQuoteRequestId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.quoteRequest?.id,
)

export const selectTaskViewQuoteListAcceptedQuoteQuoteId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => {
    return acceptedQuote?.id
  },
)

export const selectTaskViewQuoteListAcceptedQuoteCompanyRatingId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.companyRatingPublicId
)

export const selectTaskViewQuoteListAcceptedQuoteCompanyId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.company?.id
)