import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { DialogContent } from "@mui/material";
import { RouteDefinitions } from "common";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_components";
import { ProjectTaskStatus as ProjectTaskStatusModel } from "modules/project/_models";
import { selectFirstBookingTaskId, selectFirstTaskId, selectProjectTask, selectProjectTaskId, selectProjectTaskStatus, selectProjectTaskType } from "modules/project/_selectors";
import { useEffect, useState } from "react";
import { Route } from "react-router";
import { useTaskViewParams } from "./_hooks";
import TaskViewDrawerView from "./task-view-drawer/TaskDrawerView";
import TaskViewReviewRouteDefinitions from "./task-view-review/TaskViewReviewRouteDefinitions";
import { TaskViewReviewActions } from "./task-view-types/_common";
import TaskViewMessageActions from "./task-view-types/_common/task-view-message-actions/TaskViewMessageActions";
import taskViewTypeFactory from "./task-view-types/taskViewTypeFactory";

function isFirstBookingTask(projectTaskId: string, firstBookingTaskId?: string) {
  return projectTaskId === firstBookingTaskId
}

function isFirstTask(projectTaskId: string, selectFirstTaskId?: string) {
  return projectTaskId === selectFirstTaskId
}

function isTaskTypeShowMedia(taskType: CoreState.Task.TaskType | undefined) {
  return taskType === CoreState.Task.TaskType.ShowMedia
}

function isTaskNotCompleted(status?: ProjectTaskStatusModel) {
  return status !== ProjectTaskStatusModel.AwaitingNextTask && status !== ProjectTaskStatusModel.Completed
}

const TaskView = () => {
  // hooks
  const routing = CoreRouting.useRouting();
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };
  const defectParams = { projectId, stageId, taskId };

  // queries
  const taskQuery = CoreState.Task.getTaskListByParent.useQuery(taskParams);
  const defectQuery = CoreState.Defect.getDefectListByTask.useQuery(defectParams);

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));
  const projectTaskId = useSelector((s) => selectProjectTaskId(s, taskParams));
  const projectTaskType = useSelector((s) => selectProjectTaskType(s, taskParams));
  const isUserLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);
  const firstBookingTaskId = useSelector((s) => selectFirstBookingTaskId(s, taskParams));
  const firstTaskId = useSelector((s) => selectFirstTaskId(s, taskParams));

  // vars
  const [originalId, setOriginalId] = useState(projectTaskId);
  const [originalStatus, setOriginalStatus] = useState(projectTaskStatus);
  const isLoading = taskQuery.isLoading || defectQuery.isLoading;
  const isFetching = taskQuery.isFetching;
  
  // TODO: tidy up this logic
  const showActions =
    (!isUserLimited && isTaskNotCompleted(projectTaskStatus))
    || isFirstBookingTask(projectTaskId, firstBookingTaskId)
    || isTaskTypeShowMedia(projectTaskType)
    || isFirstTask(projectTaskId, firstTaskId)

  // factories
  const taskViewType = taskViewTypeFactory(projectTaskType);

  const handleClose = () => {
    if (!projectTask) return;
    routing.back();
  }

  const handleNavigate = (task: CoreState.Task.Task) => {
    const params = { tid: task.parentId };
    routing.go(RouteDefinitions.Task.TASK_VIEW, { params });
  }

  useEffect(() => {
    if (!projectTaskId) return;
    // this is the logic to close the modal if the task is completed (changes Id)
    if (originalId && projectTaskId !== originalId) handleClose();
    if (originalStatus && projectTaskStatus !== originalStatus) handleClose();
    else {
      setOriginalId(projectTaskId);
      setOriginalStatus(projectTaskStatus);
    }
  }, [projectTaskId, projectTaskStatus]);

  return (
    <>
      <CoreModules.Task.TaskView.TaskView
        onClose={handleClose}
      >
        <CoreComponents.PreContent
          isLoading={isLoading}
          isEmpty={false}
          loader={<CoreModules.Task.TaskView.TaskViewSkeleton onClose={handleClose} />}
        >
          <CoreComponents.Loader show={isFetching} />
          <CoreModules.Task.TaskView.TaskViewHeader
            currentTask={projectTask?.task}
            tasks={[]}
            status={() => <ProjectTaskStatus projectTask={projectTask} />}
            onNavigate={handleNavigate}
            onClose={handleClose}
          />
          <DialogContent
            sx={{
              padding: "0 !important",
            }}
          >
            <CoreModules.Task.TaskView.TaskViewContent
              name={projectTask?.task.name}
              description={projectTask?.task.description}
              actions={
                <TaskViewMessageActions>
                  {showActions
                    ? taskViewType?.actions
                    : <TaskViewReviewActions />
                  }
                </TaskViewMessageActions>
              }
              content={taskViewType?.content}
              drawer={<TaskViewDrawerView />}
            />
            <Route
              exact
              component={TaskViewReviewRouteDefinitions.TASK_VIEW_REVIEW.component}
              path={TaskViewReviewRouteDefinitions.TASK_VIEW_REVIEW.path}
            />
            <Route
              exact
              component={TaskViewReviewRouteDefinitions.TASK_VIEW_REVIEW_EDIT.component}
              path={TaskViewReviewRouteDefinitions.TASK_VIEW_REVIEW_EDIT.path}
            />
          </DialogContent>
        </CoreComponents.PreContent>
      </CoreModules.Task.TaskView.TaskView>
    </>
  )
}

export default TaskView;