import { CoreComponents, CoreState, useDebounce } from "@build-buddy/core";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { calculateTotal } from "../draft-financials/DraftFinancials";
import FinancialsPagination from "../FinancialsPagination";
import TableHeaderItem from "../TableHeaderItem";
import TableSkeleton from "../TableSkeleton";
import QuantitySurveyorCostListItem from "./QuantitySurveyorCostListItem";
import QuantitySurveyorCostListTemplate from "./QuantitySurveyorCostListTemplate";

const QuantitySurveyorCost = () => {
  const match = useRouteMatch();
  const history = useHistory();

  // Table header items
  const tableHeaders = [
    { label: 'Item', gridArea: 'item', fieldName: 'description', align: 'left' },
    { label: 'Quantity', gridArea: 'qty', fieldName: 'quantity' },
    { label: 'Unit', gridArea: 'unit', fieldName: 'unitType', ml: 2 },
    { label: 'Rate', gridArea: 'rate', fieldName: 'rate', ml: 2 },
    { label: 'Total Amount', gridArea: 'total', fieldName: 'variation' },
    { label: 'Notes', gridArea: 'notes', fieldName: '', ml: 2, align: 'left' }
  ];
  // Current page for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Search filter states
  const [sectionSearchKeyword, setSectionSearchKeyword] = useState('');
  const [itemSearchKeyword, setItemSearchKeyword] = useState('');
  const debouncedSectionSearchTerm = useDebounce(sectionSearchKeyword, 500);
  const debouncedItemSearchTerm = useDebounce(itemSearchKeyword, 500);

  useEffect(() => {
    if (sectionSearchKeyword === '' && itemSearchKeyword === '') return;
    if (currentPage === 1) return;
    setCurrentPage(1);
  }, [sectionSearchKeyword, itemSearchKeyword])

  // selectors
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);

  const getProjectEstimateItemListQuery = CoreState.ProjectFinancials.getProjectEstimateItemList.useQuery({
    projectId: projectId,
    BOQVersionType: 'QuantitySurveyor',
    currentPage,
    pageSize: 20,
    sectionSearchKeyword: debouncedSectionSearchTerm,
    nameSearchKeyword: debouncedItemSearchTerm,
  });

  const data = getProjectEstimateItemListQuery.data;

  return (
    <Card sx={{ minWidth: '1250px' }}>
      <CardHeader
        title={
          <QuantitySurveyorCostListTemplate>
            {tableHeaders?.map((header, i) => {
              return <Box key={i}>
                <TableHeaderItem
                  title={header.label}
                  gridArea={header.gridArea}
                  align={header.align}
                  ml={header.ml}
                  onItemSearchChange={setItemSearchKeyword}
                  onSectionSearchChange={setSectionSearchKeyword}
                />
              </Box>;
            })}
          </QuantitySurveyorCostListTemplate>
        }
      />
      <CoreComponents.PreContent
        isLoading={getProjectEstimateItemListQuery.isLoading || getProjectEstimateItemListQuery.isFetching}
        isEmpty={!data?.length}
        loader={<TableSkeleton />}
      >
        <CardContent>
          {data?.map((d: any, idx: any) => (
            <QuantitySurveyorCostListItem
              key={idx}
              title={d.description}
              amount={d.budget || 0}
              quantity={d.quantity || 0}
              unit={d.unitType.type}
              rate={d.rate}
              total={calculateTotal(d.rate, d.quantity, d.unitType.divisor)}
              notes={d.notes}
              onEditClick={() => history.push(`${match.url}/${d.id}/edit`)}
            />
          ))}
        </CardContent>
      </CoreComponents.PreContent>
      <FinancialsPagination
        dataLength={(data && data.length) || 0}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Card>
  );
};
export default QuantitySurveyorCost;
