import { useEffect } from 'react';
import { CoreState } from '@build-buddy/core';
import UploadFinanceDialog from './UploadFinanceDialog';
import { useSelector } from 'react-redux';
import { CommonState } from "common";

interface UploadFinancialsProps {
  open: boolean;
  onClose: () => void;
  selectedVersion: string;
}

const UploadFinancials = ({ open, onClose, selectedVersion }: UploadFinancialsProps) => {
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  // Mutations
  const [uploadFinancialsMutation, uploadFinancialsQuery] = CoreState.ProjectFinancials.uploadFinancials.useMutation();

  // Upload handler
  const handleUploadSubmitClick = async (values: any) => {
    const version = values.version;
    const files = values?.files?.toSave[0];
    uploadFinancialsMutation({ files, version, projectId });
  };

  useEffect(() => {
    if (uploadFinancialsQuery?.isSuccess) {
      onClose();
    }
  }, [uploadFinancialsQuery.isSuccess])

  return (
    <UploadFinanceDialog
      open={open}
      onClose={onClose}
      onSubmitClick={handleUploadSubmitClick}
      error={(uploadFinancialsQuery?.error as any)?.message?.message}
      loading={uploadFinancialsQuery.isLoading}
      selectedVersion={selectedVersion}
    />
  )
}

export default UploadFinancials;