import { CoreModules, CoreRouting, CoreState } from '@build-buddy/core';
import { selectProjectTask, selectProjectTaskListByParent } from 'modules/project/_selectors';
import { useTaskViewParams } from '../_hooks';
import TaskViewTimelineTaskList from './TaskViewTimelineTaskList';
import { useSelector } from 'common/state/store';

const TaskViewTimeline = () => {
  const routing = CoreRouting.useRouting();
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };

  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTasks = useSelector((s) => selectProjectTaskListByParent(s, taskParams));

  const isSelect = Boolean(projectTask?.task.type === CoreState.Task.TaskType.Select)
  
  const handleClose = () => {
    routing.back();
  }

  if (!projectTasks) return null;

  return (
    <CoreModules.Task.TaskView.TaskViewDrawer
      title="Task Timeline"
      description=""
      open={true}
      onClose={handleClose}      
    >
      <TaskViewTimelineTaskList
        projectId={projectId}
        projectTasks={projectTasks}
        readonly={isSelect}
      />      
    </CoreModules.Task.TaskView.TaskViewDrawer>
  )
}

export default TaskViewTimeline