import { Stack, TextField, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react';

interface TableHeaderItemProps {
  title: string;
  gridArea: string;
  ml?: number;
  sectionSearchKeyword?: string;
  itemSearchKeyword?: string;
  onItemSearchChange: Dispatch<SetStateAction<string>>;
  onSectionSearchChange: Dispatch<SetStateAction<string>>;
  align?: string;
}

const TableHeaderItem = (props: TableHeaderItemProps) => {
  const {
    title,
    gridArea,
    onSectionSearchChange,
    onItemSearchChange,
    ml = 0,
    align,
    sectionSearchKeyword,
    itemSearchKeyword
  } = props;

    return(
      <Stack>
        <Typography
          variant="h5"
          sx={{
            gridArea: gridArea,
            display: 'flex',
            alignItems: 'center',
            textAlign: 'right',
            gap: 0.5,
            justifyContent: align !== 'left' ? 'flex-end' : 'flex-start',
            cursor: 'pointer',
            ml: ml,
            mb: 0.4
          }}
        >
          {title}
        </Typography>
        {title === 'Section' &&
          <TextField
            size="small"
            placeholder={`Search section`}
            value={sectionSearchKeyword}
            onChange={e => onSectionSearchChange(e.target.value)}
          />
        }
        {title === 'Item' &&
          <TextField
            size="small"
            placeholder={`Search item`}
            value={itemSearchKeyword}
            onChange={e => onItemSearchChange(e.target.value)}
          />
        }
        {title === 'Cost Centre Name' &&
          <TextField
            size="small"
            placeholder={`Cost Centre Name`}
            onChange={e => onSectionSearchChange(e.target.value)}
          />
        }
      </Stack>
    )
}

export default TableHeaderItem