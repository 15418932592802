import { CoreComponents, CoreHooks, CoreModules, CoreRouting, CoreState, CoreUtils } from '@build-buddy/core';
import { KeyboardArrowUp as KeyboardArrowUpIcon, Summarize as SummarizeIcon } from '@mui/icons-material';
import { Box, Collapse, Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'common/state/store';
import { useState } from 'react';
import { useTaskViewParams } from '../../_hooks';
import { selectTotalPaymentScheduleAmount } from '../../_selectors';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';
import ExpenseActions from './TaskViewExpenseActions';

const TaskViewExpenses = () => {
  const theme = useTheme();
  const mode = CoreHooks.useMode();
  const routing = CoreRouting.useRouting();
  const taskViewParams = useTaskViewParams();

  // State
  const [open, setOpen] = useState(true);

  // Query
  const params = {
    projectId: taskViewParams.projectId,
    stageId: taskViewParams.stageId,
    taskId: taskViewParams.taskId
  }
  const getExpenseByTaskParams = {
    projectId: taskViewParams.projectId,
    taskId: taskViewParams.taskId
  }

  // Query
  const getExpenseListQuery = CoreState.Financials.getExpenseListByAcceptedQuote.useQuery(params);
  const getExpensesByTask = CoreState.Financials.getExpenseListByTaskId.useQuery(getExpenseByTaskParams);

  // Selectors
  const expenses = useSelector((state) => CoreState.Financials.selectExpensesByAcceptedQuoteOrTask(state, params));
  const totalExpenseAmount = useSelector((state) => CoreState.Financials.selectWorkorderTotalExpensesAmount(state, params));
  const totalPaymentScheduleAmount = useSelector((state) => selectTotalPaymentScheduleAmount(state, params));
  const totalAmount = Number(totalExpenseAmount) + Number(totalPaymentScheduleAmount);

  const handleAddClick = () => {
    routing.go(TaskViewPaymentsRouteDefinitions.ADD_EXPENSE)
  }

  const handleExpenseClick = (expense: CoreState.Financials.Expense) => {
    const params = {
      psid: expense.id
    }
    routing.go(TaskViewPaymentsRouteDefinitions.VIEW_EXPENSE, { params })
  }

  return (
    <>
      <Stack direction="row" onClick={() => setOpen(prev => !prev)} sx={{ cursor: 'pointer' }} mb={2}>
        <KeyboardArrowUpIcon sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
        <Typography variant='h4'>Additional Expenses</Typography>
      </Stack>
      <Collapse in={open}>
        <CoreComponents.PreContent
          isLoading={getExpenseListQuery.isLoading || getExpensesByTask.isLoading}
          isEmpty={!expenses?.length}
          loader={<CoreModules.Task.TaskView.PaymentScheduleSkeleton />}
          empty={
            <Box sx={{ display: 'flex', gap: 1, opacity: 0.6 }}>
              <SummarizeIcon sx={{ opacity: 0.4 }} />
              <Typography>No addtional expenses.</Typography>
            </Box>
          }
        >
          <CoreModules.Financials.Expenses.ExpenseList
            expenses={expenses as Array<CoreState.Financials.Expense>}
            actions={ExpenseActions}
            onExpenseClick={handleExpenseClick}
          />
        </CoreComponents.PreContent >
      </Collapse>
      <CoreComponents.AddButton
        label='Add Expense'
        onClick={handleAddClick}
        sx={{ my: 1 }}
      />
      <CoreModules.Financials.Expenses.ExpenseTemplate
        sx={{
          pb: 2,
          pt: 4,
          px: 3,
          borderTop: `solid 1px ${theme.palette.grey[200]}`,
          mx: -3,
        }}
      >
        <Typography variant="h5" sx={{ width: '50%' }}>Total Payment</Typography>
        {mode.md &&
          <>
            <Typography></Typography>
            <Typography></Typography>
          </>
        }
        <Typography variant="h5" sx={{ width: '50%' }}>{CoreUtils.Formatter.currency(totalAmount)}</Typography>
      </CoreModules.Financials.Expenses.ExpenseTemplate>
    </>
  )
}

export default TaskViewExpenses