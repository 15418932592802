import { Box } from "@mui/material";
import DifferenceChipPositive from "./DifferenceChipPositive";
import DifferenceChipNegative from "./DifferenceChipNegative";
interface DifferenceChipProps {
  budgeted?: number;
  actual?: number;
}
const DifferenceChip = (props: DifferenceChipProps) => {
  if (!props.budgeted || !props.actual) return <Box></Box>;

  const percent = (props.actual / props.budgeted * 100);
  const offset = (100 - percent);
  const value = props.budgeted - props.actual;

  return offset >= 0 ?
    <DifferenceChipPositive percent={percent} offset={offset} value={value} /> :
    <DifferenceChipNegative percent={percent} offset={offset} value={value} />  
}
export default DifferenceChip;