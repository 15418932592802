import { CoreRouting } from "@build-buddy/core";
import CompanyView from "./CompanyView";
import CompanyReviews from "./CompanyReviews";

const CompanyRoutesDefinitions = {
  COMPANY_VIEW: {
    path: "/company/:cid",
    component: CompanyView
  },
  COMPANY_REVIEWS: {
    path: "/company/:cid/reviews",
    component: CompanyReviews
  }
}

const CompanyRoutes = () => {
  return (
    <CoreRouting.Routes 
      routes={[
        CompanyRoutesDefinitions.COMPANY_VIEW,
        CompanyRoutesDefinitions.COMPANY_REVIEWS
      ]} 
    />
  )
}

export { CompanyRoutesDefinitions, CompanyRoutes };
