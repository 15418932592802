import { CoreComponents, CoreState, CoreUtils } from '@build-buddy/core';
import { RootState } from '@build-buddy/core/dist/state';
import { Close as CloseIcon, Edit as EditIcon, Info as InfoIcon, Payment as PaymentIcon } from "@mui/icons-material";
import { Alert, Box, Card, Collapse, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTaskViewPaymentsParams } from '../_hooks';
import { selectCompanyByPaymentSchedule, selectCurrentPaymentSchedule } from '../_selectors';
import CreditCardUI from './CreditCardUI';
import TaskViewMakePayment from './TaskViewMakePayment';
import HostedCreditCardForm from './form/HostedCreditCardForm';
import { CommonState } from 'common';

type ResponseType = {
  type: 'error' | 'success';
  message: string;
  isLoading: boolean;
}

const TaskViewPaymentsOptions = () => {
  const theme = useTheme()

  // Params
  const { projectId, stageId, taskId, paymentScheduleId } = useTaskViewPaymentsParams();
  const paymentScheduleParams = { projectId, stageId, taskId, paymentScheduleId };

  // Internal state
  const [responseFromZai, setResponseFromZai] = useState<ResponseType>({ type: 'error', message: '', isLoading: false })

  // Payment method;
  const [currentMethod, setCurrentMethod] = useState(CoreState.Wallet.PaymentGateway.Wallet)
  const [editCreditCard, setEditCreditCard] = useState(false)

  // PAYMENT LIMIT FOR CREDIT CARD
  const cardLimit = 50000;

  // Selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const cardDetails = useSelector((state: RootState) => CoreState.Wallet.selectCreditCardDetails(state, { userId }));
  const currentPaymentSchedule = useSelector((s) => selectCurrentPaymentSchedule(s, paymentScheduleParams));
  const bankDetails = useSelector((state: RootState) => CoreState.Wallet.selectBankDetails(state, { userId: userId }));
  const company = useSelector((s) => selectCompanyByPaymentSchedule(s, paymentScheduleParams));
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);

  // Query 
  const getFinancialsDetails = CoreState.Wallet.getFinancialDetails.useQuery({ userId })

  // Handle the response coming from ZAI
  const handleResponse = (response: ResponseType) => {
    setResponseFromZai(response);
  }

  useEffect(() => {
    if (responseFromZai.type === 'success') {
      setEditCreditCard(false)
    }
  }, [responseFromZai.type])

  if (!currentPaymentSchedule) return null

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant='h6'>Source</Typography>
          <Card sx={{ border: `1px solid ${theme.palette.grey[300]}`, px: 2, py: 1, my: 1 }}>
            <CoreComponents.Loader show={responseFromZai.isLoading || getFinancialsDetails.isFetching || getFinancialsDetails.isLoading} />
            <RadioGroup
              defaultValue={CoreState.Wallet.PaymentGateway.Wallet}
              name="payment_method"
              onChange={(e) => {
                setCurrentMethod(e.target.value as CoreState.Wallet.PaymentGateway);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mx: -2,
                  px: 3,
                  pt: 0.5,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                <FormControlLabel
                  value={CoreState.Wallet.PaymentGateway.Wallet}
                  control={<Radio />}
                  label={
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                    >
                      <CoreComponents.PayToIcon height={20} width={20} />
                      <Typography variant='h5'>PayTo</Typography>
                    </Stack>
                  }
                />
                <Collapse in={currentMethod === CoreState.Wallet.PaymentGateway.Wallet}>
                  <Box sx={{ maxWidth: '350px', my: 2, pl: 3.5 }}>
                    <Typography variant='h1'>{CoreUtils.Formatter.currency(currentPaymentSchedule?.amount)}</Typography>
                    <Stack mt={2} gap={.5}>
                      <Typography variant='subtitle1'>Send to:</Typography>
                      <Typography variant='h3'>{company?.name}</Typography>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 2 }}>
                        <InfoIcon />
                        <Typography>No transaction fee</Typography>
                      </Box>
                    </Stack>
                  </Box>
                  {!bankDetails?.accountName &&
                    <Alert severity="error" sx={{ mb: 2 }}>
                      Bank account is required to use PayTo.
                      {!isCurrentUserOwner && ` Please contact project owner.`}
                    </Alert>
                  }
                </Collapse>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  mx: -2,
                  px: 3,
                  pb: 0.5,
                }}
              >
                <FormControlLabel
                  value={CoreState.Wallet.PaymentGateway.Card}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                      >
                        <PaymentIcon height='15' width='40' sx={{ color: theme.palette.grey[500] }} />
                        <Typography variant='h5'>Credit Card</Typography>
                      </Stack>
                      <IconButton onClick={() => setEditCreditCard(prev => !prev)} sx={{ position: 'absolute', right: 12, top: 8 }}>
                        {currentMethod === CoreState.Wallet.PaymentGateway.Card && (editCreditCard ?
                          <CloseIcon /> :
                          <EditIcon />)}
                      </IconButton>
                    </Box>
                  }
                  disabled={Boolean(currentPaymentSchedule?.amount >= cardLimit)}
                />
                <Collapse in={currentMethod === CoreState.Wallet.PaymentGateway.Card}>
                  <Box
                    sx={{
                      pl: 3.5,
                      pb: 2
                    }}
                  >
                    {cardDetails?.number && !editCreditCard ?
                      <CreditCardUI
                        cardHolder={cardDetails.fullName}
                        cardNumber={cardDetails.number}
                        expiry={`${cardDetails.expiryYear}-${cardDetails.expiryMonth}`}
                      />
                      :
                      <HostedCreditCardForm
                        handleResponse={handleResponse}
                        isEditForm={editCreditCard}
                      />}
                  </Box>
                </Collapse>
              </Box>
            </RadioGroup>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <TaskViewMakePayment
            currentMethod={currentMethod}
          />
        </Grid>
      </Grid >
      <CoreComponents.Snackbar
        open={Boolean(responseFromZai.message)}
        severity={responseFromZai.type}
        message={responseFromZai.message}
      />
    </>
  )
}

export default TaskViewPaymentsOptions