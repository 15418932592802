import * as Yup from "yup";
import { differenceInDays, endOfDay } from "date-fns";

const ExpenseFormValidation = Yup.object().shape({
  payeeName: Yup.string().required("Please enter a payee name"),
  description: Yup.string(),
  amount: Yup.string().required("Please enter an amount"),
  paidDate: Yup.mixed()
    .required("Please enter valid date")
    .test("validPaidDate", "Date cannot be in the future", (value: any) => {
      const today = endOfDay(new Date());
      const date = endOfDay(new Date(value));
      return differenceInDays(today, date) >= 0;
    }),
});

export default ExpenseFormValidation;
