import { useProjectParams } from "modules/project/_hooks";
import { ProjectTaskFilterStatus } from "modules/project/_models";
import { useRouteMatch } from "react-router";
import DashboardRouteDefinitions from "../../DashboardRouteDefinitions";

const useDashboardTasksList = () => {
  const { projectId } = useProjectParams();
  const dashboardMatch = useRouteMatch<any>(DashboardRouteDefinitions.DASHBOARD);

  const kvp: any = {
    "to-do": ProjectTaskFilterStatus.Todo,
    "overdue": ProjectTaskFilterStatus.Overdue,
    "in-progress": ProjectTaskFilterStatus.InProgress,
    "awaiting": ProjectTaskFilterStatus.Awaiting,
    "completed": ProjectTaskFilterStatus.Completed,
  }

  return {
    projectId,
    filterStatus: kvp[dashboardMatch?.params.filter]
  }
}
export default useDashboardTasksList;