import { CoreUtils, useMode } from "@build-buddy/core";
import { Layout, Text } from "@progress/kendo-drawing";
import { Circle as CircleGeometry } from "@progress/kendo-drawing/geometry";
import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, RenderEvent, SeriesVisualArgs } from "@progress/kendo-react-charts";
import { useProjectParams } from "modules/project/_hooks";
import { selectDashboardFinancialsBoqItemsNotUploaded, selectDashboardFinancialsChart, selectDashboardFinancialsTotal } from "../_selectors";
import "./DashboardFinancialsPieChart.scss";
import { useSelector } from "common/state/store";

let center: any;
let radius: any;

const visualHandler = (e: SeriesVisualArgs) => {
  center = e.center;
  radius = e.innerRadius;
  return e.createVisual();
};
const onRender = (e: RenderEvent, sizes: any, total: number, boqItemsNotUploaded: boolean) => {
  if (!e.target.surface) return;

  const circleGeometry = new CircleGeometry(center, radius);
  const bbox = circleGeometry.bbox();

  const heading = new Text(
    CoreUtils.Formatter.currency(total) || "$0", [0, 0], {
    font: `bold ${sizes.price}px Poppins,Roboto`,
  });

  const forecast = new Text(`${boqItemsNotUploaded ? "Costs to date" : "Forecast total"}`, [0, 0], {
    font: `bold ${sizes.forecast}px Poppins,Roboto`,
    fill: {
      color: "#687077"
    }
  });

  const layout = new Layout(bbox, {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    spacing: 100,
  });

  layout.append(heading);
  layout.append(forecast);
  layout.reflow();

  e.target.surface.draw(layout);
};

const useSizes = () => {
  const mode = useMode();
  const large = {
    height: 250,
    hole: 100,
    price: 24,
    forecast: 18
  };

  const small = {
    height: 175,
    hole: 65,
    price: 17,
    forecast: 13
  }
  if (mode.lg || mode.xl) return large;
  if (mode.md) return large;
  if (mode.sm) return large;
  return small
}

const DashboardFinancialsPieChart = () => {
  // hooks
  const sizes = useSizes();
  const { projectId } = useProjectParams();

  // selectors
  const chart = useSelector((s) => selectDashboardFinancialsChart(s, { projectId }));
  const total = useSelector((s) => selectDashboardFinancialsTotal(s, { projectId }));
  const boqItemsNotUploaded = useSelector((s) => selectDashboardFinancialsBoqItemsNotUploaded(s, { projectId }));

  return (
    <Chart
      onRender={(e) => onRender(e, sizes, total, boqItemsNotUploaded)}
      transitions={false}
    >
      <ChartArea
        height={sizes.height}
      />
      <ChartSeries>
        <ChartSeriesItem
          padding={0}
          type="donut"
          holeSize={sizes.hole}
          data={chart}
          categoryField="key"
          field="value"
          visual={visualHandler}
        />
      </ChartSeries>
      <ChartLegend visible={false} />
    </Chart>
  )
}
export default DashboardFinancialsPieChart;