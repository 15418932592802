import { CoreModules } from "@build-buddy/core";
import { useCompanyParams } from "./_hooks";

const CompanyReviews = () => {
  const { companyId } = useCompanyParams();

  return (
    <CoreModules.Company.CompanyReviews companyId={companyId} />
  )
}
export default CompanyReviews;