
import * as yup from 'yup'

const FileUploadValidationSchema = yup.object().shape({
    version: yup.mixed().required("Version is required."),
    files: yup
      .mixed()
      .required("A file is required")
      .test(
        "file check",
        "File is required.",
        (value) => {
            return value.toSave?.length
        }
      )
  });

  export default FileUploadValidationSchema;