
import { CoreRouting, useMode } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { Route } from "react-router";
import CalendarGuard from "./CalendarGuard";
import CalendarRouteDefinitions from "./CalendarRouteDefinitions";
import { useCalendarParams } from "./_hooks";
import CalendarDrawer from "./calendar-drawer/CalendarDrawer";
import CalendarView from "./calendar-view/CalendarView";

const Calendar = () => {
  const routing = CoreRouting.useRouting();
  const calendar = useCalendarParams();
  const mode = useMode();

  useEffect(() => {
    if (calendar.isValidRoute) return;
    const params = {
      filter: "all",
      year: calendar.year,
      month: calendar.month
    }
    routing.go(CalendarRouteDefinitions.CALENDAR, { params });
  }, [])

  useEffect(() => {
    if (mode.md || calendar.day) return;
    const params = {
      filter: "all",
      year: calendar.year,
      month: calendar.month,
      day: new Date().getDate()
    }
    routing.go(CalendarRouteDefinitions.CALENDAR_DRAWER, { params });
  }, [mode.md])

  return (
    <CalendarGuard>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row"
          }
        }}
      >
        <CalendarView />
        <Route
          path={CalendarRouteDefinitions.CALENDAR_DRAWER.path}
          component={CalendarDrawer}
        />
      </Box>
    </CalendarGuard>
  );
};

export default Calendar;
