import { CoreUtils } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectDashboardFinancials } from "../_selectors";
import DashboardFinancialsPieLegendItem from "./DashboardFinancialsPieLegendItem";

const DashboardFinancialsPieLegend = () => {
  const { projectId } = useProjectParams();

  // selectors
  const financials = useSelector((s) => selectDashboardFinancials(s, { projectId }));

  if (!financials) return null;

  return (
    <Box>
      <DashboardFinancialsPieLegendItem
        label="Committed"
        value={CoreUtils.Formatter.currency(financials.committed.value) || ""}
        color={financials.committed.color}
      />
      <DashboardFinancialsPieLegendItem
        label="Due"
        value={CoreUtils.Formatter.currency(financials.due.value) || ""}
        color={financials.due.color}
      />
      <DashboardFinancialsPieLegendItem
        label="Allocated"
        value={CoreUtils.Formatter.currency(financials.allocated.value) || ""}
        color={financials.allocated.color}
      />
      <DashboardFinancialsPieLegendItem
        label="Paid"
        value={CoreUtils.Formatter.currency(financials.paid.value) || ""}
        color={financials.paid.color}
      />
      <DashboardFinancialsPieLegendItem
        label="Balance"
        value={CoreUtils.Formatter.currency(financials.balance.value) || ""}
        color={financials.balance.color}
      />
    </Box>
  )
}
export default DashboardFinancialsPieLegend;