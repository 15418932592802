import { CoreComponents, CoreState } from "@build-buddy/core";
import { Dialog, DialogActions, DialogContent, MenuItem } from "@mui/material";
import { Formik } from "formik";
import FileUploadValidationSchema from "./FileUploadValidationSchema";

interface UploadFinanceDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmitClick: (values: any) => void;
  error?: string;
  loading?: boolean;
  uploadError?: string;
  selectedVersion: string;
}

const UploadFinanceDialog = ({
  open,
  onClose,
  onSubmitClick,
  error,
  loading,
  selectedVersion
}: UploadFinanceDialogProps) => {

  // queries
  const getBoqVersions = CoreState.ProjectFinancials.getBoqVersions.useQuery(null);
  const versions = getBoqVersions.data?.versions as CoreState.ProjectFinancials.BoqVersionType[];

  return (
    <>
      <Formik
        initialValues={{
          files: {
            toRemove: [],
            toSave: [],
          },
          version: selectedVersion
        }}
        enableReinitialize={true}
        validationSchema={FileUploadValidationSchema}
        onSubmit={(values) => { onSubmitClick(values) }}
      >
        {(formik) => (
          <Dialog
            open={open}
            onClose={onClose}
            sx={{ maxWidth: '1000px', mx: 'auto' }}
          >
            <CoreComponents.DialogHeader
              onClose={() => {
                onClose()
              }}
              title="Upload File"
            />
            <DialogContent >
              <CoreComponents.Select
                name="version"
                label="Version"
                fullWidth
                value={formik.values.version}
                error={formik.errors.version}
                onChange={formik.handleChange}
              >
                {versions?.map((version, i) => {
                  return (
                    <MenuItem key={version.code} value={version.code}>
                      {version.description}
                    </MenuItem>
                  )
                })}
              </CoreComponents.Select>
              <CoreComponents.FormikUploadFile
                name="files"
                maxFiles={1}
                handleDownload={() => { }}
                acceptFiles={[".xlsx", ".xls", ".csv"]}
                emptyField={!open}
              />
              <CoreComponents.Loader fullscreen={false} show={Boolean(loading)} />
            </DialogContent>
            <DialogActions>
              <CoreComponents.Actions
                onCancelClick={() => {
                  onClose()
                }}
                onSubmitClick={() => formik.handleSubmit()}
                submitText="Upload"
                cancelText="Cancel"
              />
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default UploadFinanceDialog;