import { CoreComponents } from "@build-buddy/core";
import { useTaskViewTypesTabs } from "../_hooks";

const TaskViewTabs = () => {
  const tabs = useTaskViewTypesTabs();  
  return (
    <CoreComponents.Tabs
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column"
      }}
      routes={tabs}
      TabsProps={{
        sx: {
          mx: -3,
          px: 1.5
        }
      }}
    />
  )
}
export default TaskViewTabs;