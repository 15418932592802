import { createSelector } from "@reduxjs/toolkit";
import { selectProjectFinancials } from "modules/project/_selectors";

export const selectDashboardFinancials = createSelector(
  selectProjectFinancials,
  (projectFinancials) => {
    if (!projectFinancials) return;
    return {
      paid: {
        value: projectFinancials.paid,
        color: "#FF6F00",
      },
      committed: {
        value: projectFinancials.committed,
        color: "#FFBA85"
      },
      due: {
        value: projectFinancials.due,
        color: "#CB5800"
      },
      allocated: {
        value: projectFinancials.allocated,
        color: "#757575"
      },
      balance: {
        value: projectFinancials.boqItemsNotUploaded ? 0 : projectFinancials.toComplete,
        color: "#E3E6E8"
      },
      
    }
  }
)

export const selectDashboardFinancialsTotal = createSelector(
  selectProjectFinancials,
  (projectFinancials) => projectFinancials?.budget || 0
)

export const selectDashboardFinancialsBoqItemsNotUploaded = createSelector(
  selectProjectFinancials,
  (projectFinancials) => Boolean(projectFinancials?.boqItemsNotUploaded)
)

export const selectDashboardFinancialsChart = createSelector(
  selectDashboardFinancials,
  selectDashboardFinancialsTotal,
  (dashboardFinancials, dashboardFinancialsTotal) => {
    if (!dashboardFinancials || !dashboardFinancialsTotal) {
      return [{
        key: "blank",
        value: 1,
        color: "#E3E6E8"
      }]
    }
    return Object.keys(dashboardFinancials).map((key: string) => {
      const df = dashboardFinancials[key as keyof typeof dashboardFinancials];
      return { key, value: df.value, color: df.color }
    })
  }
)

const selectDashboardFinancialsDifference = createSelector(
  selectProjectFinancials,
  (projectFinancials) => projectFinancials?.difference
)

export const selectDashboardFinancialsSavings = createSelector(
  selectProjectFinancials,
  (projectFinancials) => projectFinancials?.savings
)

export const selectDashboardFinancialsIsBoquploaded = createSelector(
  selectProjectFinancials,
  (projectFinancials) => !projectFinancials?.boqItemsNotUploaded
)
