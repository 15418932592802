import { CoreRouting, CoreState } from "@build-buddy/core";
import { Typography } from "@mui/material";
import { CommonState, RouteDefinitions } from "common";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";

interface FinancialsGuardProps extends CoreRouting.GuardProps {
  children: any;
}
const FinancialsGuard = (props: FinancialsGuardProps) => {
  const { children } = props;
  const location = useLocation();
  const history = useHistory();
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);
  const isPublishedRoute = useRouteMatch(RouteDefinitions.Financials.FINANCIALS_PUBLISHED.path);
  const isDraftRoute = useRouteMatch(RouteDefinitions.Financials.FINANCIALS_DRAFT.path);
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);

  // queries
  const getProjectQuery = CoreState.Project.getProject.useQuery({ projectId: projectId }, { skip: !projectId });

  useEffect(() => {
    if (!getProjectQuery.data) return;

    // if the budget is locked & we are not on the published route, redirect
    if (getProjectQuery.data.budgetLocked && !isPublishedRoute) {
      return history.push(CoreRouting.transformRoutePath(RouteDefinitions.Financials.FINANCIALS_PUBLISHED.path, { pid: projectId }));
    }

    // if the budget is not locked & we are not on the draft route, redirect
    if (!getProjectQuery.data.budgetLocked && !isDraftRoute) {
      return history.push(CoreRouting.transformRoutePath(RouteDefinitions.Financials.FINANCIALS_DRAFT.path, { pid: projectId }));
    }
  }, [getProjectQuery.data?.budgetLocked, location]);

  if (isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          The Financials section is where all your project costs are managed and maintained and is home to both your 
          Bill of Quantities and your Quantity Surveyor reports. 
        </Typography>
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment
          with a member of the Build Buddy Expert Guidance and Support Team.
        </Typography>
      </Unavailable>
    )
  }

  return children;
};

export default CoreRouting.withGuard<FinancialsGuardProps>(FinancialsGuard);
