import { Box, SxProps, Theme, useTheme } from "@mui/material"

interface DraftFinancialsListTemplateProps {
  children: any;
  sx?: SxProps<Theme>;
}
const QuantitySurveyorCostListTemplate = (props: DraftFinancialsListTemplateProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: `'item qty unit rate total notes action'`,
        gridColumnGap: theme.spacing(1.5),
        gridTemplateColumns: "1fr 120px 75px 85px 100px 1fr 30px",
        ...props.sx
      }}
    >
      {props.children}
    </Box>
  )
}
export default QuantitySurveyorCostListTemplate;