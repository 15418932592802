import { Circle as CircleIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

type DashboardPieLegendItemProps = {
  color: string;
  label: string;
  value: string;  
}
const DashboardPieLegendItem = (props: DashboardPieLegendItemProps) => {
  return (
    <>
      <Stack 
        direction="row"       
        alignItems="center"
        sx={{ 
          mb: {
            xs: 0.5,
            sm: 1,        
            md: 1.25,
          },
          "&:last-of-type": {
            mb: 0
          }
        }}
      >
        <CircleIcon 
          sx={{ 
            mr: 1, 
            fill: props.color,
            fontSize: {
              xs: "14px",
              sm: "32px",
              lg: "24px",
              xl: "32px"
            }
          }} 
        />
        <Typography 
          variant="body1"
          sx={{ flex: 1 }} 
        > 
          {props.label} 
        </Typography>
        <Typography 
          variant="h2"
          sx={{ 
            fontSize: {
              xs: "14px",
              sm: "18px",
              lg: "16px",
              xl: "18px",
            }
          }} 
        > 
          {props.value} 
        </Typography>
      </Stack>
    </>
  )
}
export default DashboardPieLegendItem;