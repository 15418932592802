import { CoreRouting, CoreModules } from "@build-buddy/core";
import { Box, IconButton, useTheme, alpha } from "@mui/material";
import { useCompanyParams } from "./_hooks";
import { CompanyRoutesDefinitions } from "./CompanyRoutes";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { MyTeamsRoutesDefinitions } from "modules/project/my-teams/MyTeamsRoutes";

const CompanyView = () => {
  const theme = useTheme();
  const { companyId } = useCompanyParams();
  const routing = CoreRouting.useRouting();

  const handleReviewsClick = () => {
    routing.go(CompanyRoutesDefinitions.COMPANY_REVIEWS)
  }

  const handleBackClick = () => {
    routing.go(MyTeamsRoutesDefinitions.MY_TEAMS)
  }
  
  return (
    <Box sx={{ mx: -3, mt: -5 }}>
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          top: theme.variables.appBarHeight,
          m: 1,
          background: alpha(theme.palette.common.white, 0.2)
        }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon sx={{ color: 'white' }} />
      </IconButton>
      <CoreModules.Company.CompanyProfile
        companyId={companyId}
        onReviewsClick={() => handleReviewsClick()}
      />
    </Box>
  )
}
export default CompanyView;
