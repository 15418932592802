import { CoreComponents, CoreRouting, CoreState, DialogHeader } from '@build-buddy/core';
import { Dialog, DialogContent } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTaskViewParams } from '../../_hooks';
import { selectTaskViewQuoteListAcceptedQuoteQuoteId } from '../../_selectors';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';
import TaskViewExpenseForm from './TaskViewExpenseForm';

const TaskViewExpenseEdit = () => {
  const routing = CoreRouting.useRouting();
  const taskViewParams = useTaskViewParams();

  // params
  const params = {
    projectId: taskViewParams.projectId,
    stageId: taskViewParams.stageId,
    taskId: taskViewParams.taskId
  }

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(params);

  // Selector
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, params));
  const expense = useSelector((state) => CoreState.Financials.selectAcceptedQuoteOrTaskExpenseById(state, params, { expenseId: taskViewParams.paymentScheduleId }));

  // Mutation
  const [updateExpenseAction, updateExpenseQuery] = CoreState.Financials.updateExpense.useMutation();
  const [deleteDocListByExpenseAction, deleteDocListByExpenseQuery] = CoreState.File.deleteDocByExpense.useMutation();

  const handleClose = () => {
    const params = {
      sid: taskViewParams.stageId,
      tid: taskViewParams.taskId
    };
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE, { params });
  }

  const handleUpdateExpense = async (values: { expense: CoreState.Financials.Expense, docsToRemove: Array<CoreState.File.Doc> }) => {
    if (!expense) return;
    const updateExpense = updateExpenseAction({
      projectId: taskViewParams.projectId,
      workOrderId: quoteId || undefined,
      taskId: taskViewParams.taskId,
      expense: { ...values.expense, id: expense.id }
    });
    const deleteExpenseFiles = deleteDocListByExpenseAction({
      projectId: taskViewParams.projectId,
      paymentScheduleId: taskViewParams.paymentScheduleId,
      docs: values.docsToRemove
    })
    try {
      await Promise.all([updateExpense, deleteExpenseFiles]);
    } catch (e) {
      return;
    }
  }

  useEffect(() => {
    if (updateExpenseQuery.isSuccess) handleClose();
  }, [updateExpenseQuery.isSuccess])

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={updateExpenseQuery.isLoading || quoteListQuery.isLoading} />
      <DialogHeader title="Edit Expense" onClose={handleClose} />
      <DialogContent>
        <TaskViewExpenseForm
          onSubmit={handleUpdateExpense}
          onClose={handleClose}
          expense={expense}
        />
      </DialogContent>

    </Dialog>
  )
};

export default TaskViewExpenseEdit;