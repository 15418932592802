import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyStateType } from "common/state/store";

interface SelectProjectFinancialsParams {
  projectId: string;
}
export const selectProjectFinancials = createSelector(
  [
    (state: BuildBuddyStateType, params: SelectProjectFinancialsParams) =>
      CoreState.ProjectFinancials.getProjectFinancials.select({ projectId: params.projectId })(state)
  ],
  (financialsQuery) => financialsQuery?.data?.financials
)