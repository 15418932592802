import { DialogActions, DialogContent, Skeleton, Stack } from "@mui/material";

const DraftFinancialsListItemEditSkeleton = () => {
  return (
    <>
      <DialogContent>
        <Skeleton variant="text" width="100%" height={60} />
        <Stack direction="row" gap={2}>
          <Skeleton variant="rectangular" width="50%" height={40} />
          <Skeleton variant="rectangular" width="50%" height={40} />
        </Stack>
        <Skeleton variant="text" width="100%" height={60} />
      </DialogContent>
      <DialogActions>
        <Skeleton variant="rectangular" width='100%' height={40} sx={{borderRadius: '1rem'}} />
      </DialogActions>
    </>
  )
}
export default DraftFinancialsListItemEditSkeleton;