import { Box, Card, CardContent } from "@mui/material";
import DashboardFinancialsPieChart from "./DashboardFinancialsPieChart";
import DashboardFinancialsPieLegend from "./DashboardFinancialsPieLegend";

const DashboardFinancialsPie = () => {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "160px",
                sm: "250px",
              },
              mr: {
                xs: 1,
                sm: 3,
                md: 5,
                lg: 2,
                xl: 5,
              }
            }}
          >
            <DashboardFinancialsPieChart />
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <DashboardFinancialsPieLegend />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
export default DashboardFinancialsPie;