import { useRouteMatch } from "react-router";
import { RouteDefinitions } from "common/RouteDefinitions";
import Minimal from "./minimal/Minimal";
import Standard from "./standard/StandardTemplate";

// TODO: Refactor
const minimalTemplateRoutes: Array<string> = [];

Object.keys(RouteDefinitions.Onboarding).forEach((route) => {
  minimalTemplateRoutes.push(RouteDefinitions.Onboarding[route].path);
});

minimalTemplateRoutes.push("/project-quote/:id");
minimalTemplateRoutes.push("/project-quote/create");
minimalTemplateRoutes.push("/wizard/create");
minimalTemplateRoutes.push("/wizard/:wid");
minimalTemplateRoutes.push("/invitation-initial");

const standardTemplatesRoutes = [
  ...Object.values(RouteDefinitions.Project)
    .map((x) => x.path)
    .filter(Boolean),
  ...Object.values(RouteDefinitions.Chat)
    .map((x) => x.path)
    .filter(Boolean),
    ...Object.values(RouteDefinitions.Company)
    .map((x) => x.path)
    .filter(Boolean),    
  ...Object.values(RouteDefinitions.UserProfile)
    .map((x) => x.path)
    .filter(Boolean),
  ...Object.values(RouteDefinitions.Wallet)
    .map((x) => x.path)
    .filter(Boolean),
  ...Object.values(RouteDefinitions.OrganisationProfile)
    .map((x) => x.path)
    .filter(Boolean),
  ...Object.values(RouteDefinitions.Financials)
    .map((x) => x.path)
    .filter(Boolean),
  ...Object.values(RouteDefinitions.Invitation)
    .map((x) => x.path)
    .filter(Boolean),
  ...Object.values(RouteDefinitions.Help)
    .map((x) => x.path)
    .filter(Boolean),    
];
// ENDTODO

interface PageTemplateProps {
  children: any;
}

const PageTemplate = (props: PageTemplateProps) => {
  const isMinimal = useRouteMatch(minimalTemplateRoutes);
  const isStandard = useRouteMatch(standardTemplatesRoutes);

  // use the minimal template
  if (isMinimal) return <Minimal>{props.children}</Minimal>;

  // use standard template
  if (isStandard)
    return (
      <Standard>
        {props.children}
      </Standard>
    );

  // otherwise no template
  return props.children;
};
export default PageTemplate;
