import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton } from '@mui/material';


const TableSkeleton = () => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Array.from(new Array(7)).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from(new Array(5)).map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton variant="rectangular" height={30} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableSkeleton;