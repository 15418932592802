
import { CoreRouting, CoreState, useMode } from "@build-buddy/core";
import { Box, useTheme } from "@mui/material";
import { MonthView, Scheduler } from "@progress/kendo-react-scheduler";
import { useSelector } from "common/state/store";
import { add } from "date-fns";
import { useEffect, useState } from "react";
import CalendarRouteDefinitions from "../CalendarRouteDefinitions";
import { useCalendarParams } from "../_hooks";
import { selectCalendarTaskListData } from "../_selectors";
import "./CalendarView.scss";
import CalendarViewHeader from "./CalendarViewHeader";
import CalendarViewMonthViewHeaderCell from "./CalendarViewMonthViewHeaderCell";
import CalendarViewMonthViewItem from "./CalendarViewMonthViewItem";
import CalendarViewMonthViewSlot from "./CalendarViewMonthViewSlot";

const CalendarView = () => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();
  const mode = useMode();

  // params
  const { projectId, day, activeDate } = useCalendarParams();
  const params = { projectId }

  // selectors
  const calendarTaskListData = useSelector((s) => selectCalendarTaskListData(s, params))

  // queries
  const taskListQuery = CoreState.Task.getTaskListByProject.useQuery(params)

  // locals
  const [date, setDate] = useState(activeDate);
  const isLoading = taskListQuery.isLoading;

  // event handler
  const handleNavigate = (direction: number) => {
    const d = add(date, { months: direction });
    navigate(d);
  }

  const handleTodayNavigate = () => {
    const d = new Date();
    navigate(d);
  }

  const navigate = (date: Date) => {
    const params = {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    }
    if (!day) routing.go(CalendarRouteDefinitions.CALENDAR, { params });
    else setDate(date);
  }

  // side effects
  useEffect(() => {
    setDate(activeDate);
  }, [activeDate])

  if (isLoading) return <> LOADING </>;

  if (!calendarTaskListData) return null;

  return (
    <Box
      sx={{
        my: {
          xs: 0,
          md: -2
        },
        mx: {
          xs: 0,
          sm: -3
        },
        mt: {
          xs: -3,
          md: -2
        },
        flex: 2,
      }}
    >
      <CalendarViewHeader
        date={date}
        onNavigate={handleNavigate}
        onTodayNavigate={handleTodayNavigate}
      />
      <Box
        sx={{
          p: {
            xs: 0,
            md: 2
          }
        }}
      >
        <Scheduler
          height="auto"
          style={{
            borderRadius: mode.md ? "16px" : "0px",
            border: `solid 1px ${theme.palette.grey[200]}`,
            boxShadow: theme.shadows[0]
          }}
          data={calendarTaskListData}
          defaultDate={activeDate}
          date={date}
          header={() => null}
          footer={() => null}
        >
          <MonthView
            viewSlot={CalendarViewMonthViewSlot}
            dateHeaderCell={CalendarViewMonthViewHeaderCell}
            item={CalendarViewMonthViewItem}
            itemsPerSlot={10}
          />
        </Scheduler>
      </Box>
    </Box>
  );
};

export default CalendarView;
