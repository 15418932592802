import { CoreComponents, CoreState } from '@build-buddy/core';
import { DialogActions, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import ExpenseFormValidation from './ExpenseFormValidation';
import { useTaskViewParams } from '../../_hooks';


type TaskViewExpenseFormProps = {
  onSubmit: (values: any) => void;
  onClose: () => void;
  expense?: CoreState.Financials.Expense;
}

const TaskViewExpenseForm = (props: TaskViewExpenseFormProps) => {
  const { onSubmit, onClose, expense } = props;
  const taskViewParams = useTaskViewParams();
  const { download } = CoreState.File.useDownloadQueue();

  const formik = useFormik({
    initialValues: {
      payeeName: expense?.payeeName || "",
      description: expense?.description || "",
      amount: expense?.amount || "",
      paidDate: expense?.paidDate || "",
      files: {
        toSave: expense?.files || [],
        toRemove: []
      },
    },
    validationSchema: ExpenseFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const docs = values.files.toSave.map((f: any) => ({
        file: f.file,
        name: f.name,
        description: f.file?.name
      }));
      onSubmit({ expense: { ...values, files: docs }, docsToRemove: values.files.toRemove })
    }
  })

  const handleDownload = (doc: CoreState.File.Doc) => {
    const params = {
      projectId: taskViewParams.projectId,
      paymentScheduleId: taskViewParams.paymentScheduleId,
      documentId: doc.id ?? ""
    }
    download(doc, CoreState.File.downloadDocByExpense.initiate(params));
  };

  return (
    <>
      <CoreComponents.FormikTextbox
        formik={formik}
        name="payeeName"
        label='Payee Name*'
        fullWidth
        size='small'
      />
      <Stack>
        <CoreComponents.Label label='Description' sx={{ mb: 1 }} />
        <CoreComponents.FormikHtmlEditor
          formik={formik}
          name="description"
          height={150}
        />
      </Stack>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <CoreComponents.FormikTextbox
            formik={formik}
            name="amount"
            label='Amount*'
            fullWidth
            size='small'
            inputComponent={CoreComponents.Money as any}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CoreComponents.DatePicker
            name="paidDate"
            label='Payment Date*'
            fullWidth
            size='small'
            value={formik.values.paidDate}
            onChange={formik.handleChange}
            sx={{ '& .MuiOutlinedInput-input': { height: '27px' } }}
            error={formik.touched.paidDate && formik.errors.paidDate}
          />
        </Grid>
      </Grid>
      <CoreComponents.FormikUploadFile
        formik={formik}
        maxFiles={1}
        name="files"
        label="Upload Documents"
        handleDownload={handleDownload}
        acceptFiles={[".jpg", ".jpeg", ".png", ".pdf"]}
      />
      <DialogActions>
        <CoreComponents.Actions
          onSubmitClick={() => formik.handleSubmit()}
          onCancelClick={() => onClose()}
          submitText="Save"
          cancelText='Cancel'
        />
      </DialogActions>
    </>
  )
}

export default TaskViewExpenseForm